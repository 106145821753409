.wrapper {
    display: flex;
    flex-direction: column;
    height: 100%;
    width: 100%;
}

.header {
    min-height: 60px;
    padding: 20px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    color: var(--secondary-color);
}

.header .buttons-wrapper {
    display: flex;
    align-items: center;
    gap: 15px;
}

.close-icon {
    background-color: transparent;
    border: none;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    outline: none;
}

.body {
    padding: 20px;
    flex-grow: 1;
    overflow-y: auto;
}

.footer {
    padding: 15px 20px;
    display: flex;
    align-items: center;
    justify-content: flex-end;
}

.footer > *:not(:last-child) {
    margin-right: 15px;
}

/*Dialog Modle css*/

.gs-modal-paper {
    width: 100%;
}
.gs-modal :global(.MuiModal-root-MuiDialog-root hr) {
    background-color: #e4e4e4;
}

.gs-modal-action-button {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    gap: 10px;
    padding: 20px 0 0 0 !important;
}

.gs-modal-content {
    padding: 15px 20px !important;
}

.gs-modal :global(.MuiDialogTitle-root) {
    display: flex;
    height: 60px;
    justify-content: space-between;
    align-items: center;
    border-bottom: 1px solid var(--border-color);
    font-size: 14px;
    color: var(--secondary-color);
}

.gs-modal .gs-primary-button,
.gs-danger-button {
    width: 130px;
    border-radius: 6px;
}

.gs-modal-close-botton {
    min-width: 24px !important;
    height: 30px !important;
    width: 30px !important;
    background-color: var(--light-gray);
    padding: 0;
}

.gs-modal-close-botton svg {
    color: #666666;
    width: 18px;
    height: 18px;
}

.gs-secondary-button.button-drawer {
    margin-top: 15px;
}

.gs-modal :global(.MuiPaper-root) {
    border-radius: 5px;
    box-shadow: none;
    overflow: visible;
    background-color: var(--white-color);
}

.gs-modal :global(.MuiDialogContentText-root) {
    /* text-align: center; */
    font-family: var(--medium-font);
    font-size: 14px;
    line-height: 22px;
}

.gs-modal :global(.MuiBackdrop-root) {
    background-color: var(--backdrop-color);
}

.gs-modal-content :global(.MuiTypography-h6) {
    color: var(--secondary-color);
    font-family: var(--semi-bold-font);
}

.gs-modal :global(.MuiDialogTitle-root + .MuiDialogContent-root) {
    padding-top: 20px;
    overflow: visible;
}

.close-button {
    width: 32px;
    height: 32px;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    border: 1px solid var(--border-color) !important;
    border-radius: 5px !important;
    overflow: hidden;
}
.close-button:hover {
    background-color: #0000000d;
}
.close-button :global(.MuiTouchRipple-root) {
    border-radius: unset;
}

.description {
    color: #646f79;
    font-size: 14px !important;
    font-family: var(--regular-font) !important;
}
