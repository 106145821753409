.gs-phone-input-label {
    color: var(--black-font-color);
    margin-bottom: 10px;
    display: inline-block;
    font-size: 14px;
    font-family: var(--medium-font);
}
.gs-country-code-phone-input-wrapper {
    position: relative;
}
.gs-country-code-phone-input {
    height: 44px;
    border: 1px solid var(--border-color);
    border-radius: 5px;
}
.gs-country-code-phone-input input:disabled {
    background-color: var(--white-color) !important;
    color: var(--disable-font-color) !important;
}
.gs-country-code-phone-input input {
    background-color: var(--white-color) !important;
}
.gs-country-code-phone-input:hover {
    border: 1px solid var(--primary-color);
}
.phone-number-disabled:hover {
    border: 1px solid var(--border-color) !important;
}
.gs-country-code-phone-input:focus {
    border: 1px solid var(--primary-color);
}

.gs-country-code-phone-input :global(.flag-dropdown) {
    border: none !important;
    border-radius: 5px 0 0 5px !important;
    background-color: var(--light-gray);
}

.gs-country-code-phone-input-status {
    position: absolute;
    height: 44px;
    top: 0px;
    right: 14px;
    font-size: 15px;
    border: none;
    background: transparent;
    outline: none;
    cursor: pointer;
    color: var(--primary-color);
}

.gs-country-code-phone-input :global(.country-list .search-box) {
    font-family: var(--medium-font) !important;
    height: 34px;
    width: 268px;
    margin-left: 0px;
    padding: 5px 8px 5px;
    text-transform: capitalize;
    border-color: var(--border-color);
}

.gs-country-code-phone-input:hover {
    border-color: var(--primary-color) !important;
}

.gs-country-code-phone-input :global(.country-list .search-box::placeholder) {
    color: var(--light-font-color);
    font-size: 14px;
    font-family: var(--medium-font);
}

/* .react-tel-input .country-list .search-box::placeholder{
  color: var(--secondary-color);
} */

.gs-country-code-phone-input :global(.flag-dropdown .selected-flag .arrow) {
    display: none;
}

.gs-country-code-phone-input :global(.flag-dropdown .country-list) {
    box-shadow: 0 7px 64px #00000012;
}

.gs-country-code-phone-input :global(.flag-dropdown .selected-flag:hover),
.gs-country-code-phone-input :global(.react-tel-input .selected-flag:focus) {
    background-color: #f5f5f5;
}

.gs-country-code-phone-input :global(.flag-dropdown .country-list .country-name),
.gs-country-code-phone-input :global(.flag-dropdown .country-list .dial-code) {
    color: var(--light-font-color);
    font-family: var(--medium-font);
    font-size: 14px;
}

.gs-country-code-phone-input :global(.flag-dropdown .country-list .country.highlight) {
    background-color: transparent;
}

.gs-country-code-phone-input :global(.flag-dropdown .country-list .country.highlight .country-name),
.gs-country-code-phone-input :global(.flag-dropdown .country-list .country.highlight .dial-code) {
    color: var(--primary-color);
}

.gs-country-code-phone-input :global(.flag-dropdown .country-list .country:hover) {
    background-color: transparent;
}

.gs-country-code-phone-input :global(.flag-dropdown .country-list .country:hover .country-name),
.gs-country-code-phone-input :global(.flag-dropdown .country-list .country:hover .dial-code) {
    color: var(--primary-color);
}

.gs-country-code-phone-input :global(.flag-dropdown .country-list .country) {
    padding: 8px 16px;
}

.gs-country-code-phone-input :global(.flag-dropdown .country-list .search) {
    padding: 16px 16px 5px 16px;
}

.gs-country-code-phone-input :global(.flag-dropdown.open .selected-flag) {
    background: #f5f5f5;
}
