.gs-main {
    display: flex;
    flex-direction: column;
    height: 100vh;
    width: 100%;
    /* width: 100vw; */
    max-width: 1920px;
    margin: auto;
    overflow: hidden;
}

.gs-inner-main-wrapper {
    display: flex;
}
.gs-content-wrapper {
    width: 100%;
    /* height: calc(100vh - 90px);
  overflow: hidden auto; */
    /* min-height: calc(100vh - 90px); */
}

.gs-content-footer-wrapper {
    display: flex;
    flex-direction: column;
    width: 100%;
    height: calc(100vh - 60px);
    overflow-y: hidden;
    justify-content: space-between;
    background-color: var(--background-color);
}

@media screen and (min-width: 1920px) {
    .gs-main {
        border-left: 1px solid var(--border-color);
        border-right: 1px solid var(--border-color);
    }
}
