.gs-accordion {
    box-shadow: none !important;
    border: 1px solid var(--border-color);
    border-bottom: none;
    border-bottom-left-radius: 4px !important;
    border-bottom-right-radius: 4px !important;
    margin-bottom: 0px !important;
    border-top-left-radius: 4px !important;
    border-top-right-radius: 4px !important;
    background-color: var(--white-color) !important;
}
.gs-accordion :global(.MuiAccordionSummary-root .MuiTypography-root) {
    font-family: var(--medium-font);
    font-size: 15px;
    color: var(--secondary-color);
}
.gs-accordion :global(.Mui-expanded.MuiPaper-root-MuiAccordion-root) {
    margin: 0px 0 0 0;
    border: 1px solid var(--primary-color);
}

.gs-accordion:global(.Mui-expanded) {
    border: none;
    border: 1px solid var(--border-color);
    margin: 0 !important;
}

/* .Mui-expanded .MuiCollapse-root {
    border: 1px solid var(--primary-color);
}  */

.gs-accordion :global(.MuiButtonBase-root.MuiAccordionSummary-root.Mui-expanded) {
    min-height: 55px;
    border: 1px solid var(--border-color);
    border-top-left-radius: 5px;
    border-top-right-radius: 5px;
}

.gs-accordion::before {
    background-color: transparent !important;
}

/* .gs-accordion :global(.MuiCollapse-root.MuiCollapse-entered) {
    /* border-left: 1px solid var(--primary-color);
  border-right: 1px solid var(--primary-color);
  border-bottom: 1px solid var(--primary-color);
  border-bottom-left-radius: 5px;
  border-bottom-right-radius: 5px; */

.gs-accordion :global(.MuiAccordionSummary-root .MuiAccordionSummary-content.Mui-expanded) {
    margin: 12px 15px 12px 0px;
}

.gs-accordion :global(.MuiAccordionDetails-root) .gs-accordion-detail {
    padding: 15px 20px;
    border-bottom: 1px solid var(--border-color);
}

.gs-accordion :global(.MuiCollapse-vertical) .gs-accordion-detail {
    padding: 0;
    border: 1px solid var(--border-color);
    border-top: none;
    border-bottom-left-radius: 5px;
    border-bottom-right-radius: 5px;
}

.gs-accordion :global(.MuiPaper-root.Mui-expanded .MuiTypography-root) {
    color: var(--primary-color);
}
.gs-accordion :global(.MuiPaper-root.Mui-expanded .MuiAccordionDetails-root .MuiTypography-root) {
    color: var(--secondary-light-color);
    font-size: 14px;
    font-family: var(--regular-font);
}

.gs-accordion
    :global(
        .MuiPaper-root.Mui-expanded .MuiAccordionSummary-root .MuiAccordionSummary-expandIconWrapper
    ) {
    color: var(--primary-color);
}
.gs-accordion :gobal(.MuiAccordionSummary-root .MuiAccordionSummary-expandIconWrapper) {
    color: var(--secondary-color);
}

.gs-accordion :global(.MuiPaper-root-MuiAccordion-root) {
    box-shadow: none !important;
}

.gs-accordion :global(.MuiPaper-root-MuiAccordion-root.Mui-disable) {
    background-color: rgb(0 0 0 / 5%);
}

.gs-accordion :global(.css-sh22l5-MuiButtonBase-root-MuiAccordionSummary-root) {
    /* background-color: var( --border-color); */
    border-bottom: 1px solid var(--border-color);
    min-height: 55px;
    padding: 18px 20px 18px 20px;
}

.gs-accordion :global(.MuiAccordionSummary-content) {
    margin: 0 !important;
}

.gs-accordion :global(.MuiAccordionSummary-expandIconWrapper) {
    font-size: 22px;
    color: var(--secondary-light-color);
}

.gs-accordion-button-wrapper :global(.MuiAccordionSummary-content) {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: space-between;
    align-items: center;
    margin-right: 15px;
}

.gs-accordion-detail :global(.MuiTypography-root) {
    font-family: var(--medium-font);
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 150%;
}

.gs-heading-wrapper {
    display: flex;
    flex-direction: column;
}
