[dark-theme="light"] {
    --background-color: #f8f9fa;
    --primary-color: #1e6de3;
    --primary-bk-color: #1967d2;
    /* #009be7; */
    --primary-light-color: #f0f5fe;
    /* #b7e7ff; */
    --secondary-color: #05101f;
    --secondary-bk-color: #343a40;
    --secondary-light-color: #505d6f;
    --success-color: #00b030;
    --success-bk-color: #21b648;
    --success-light-color: #b0f9c4;
    --success-light-2-color: #e9f7f2;
    --success-light-3-color: #d9f2df;
    --info-color: #0367db;
    --info-bk-color: #64acff;
    --info-light-color: #9fccff;
    --info-light-2-color: #e6effa;
    --info-light-3-color: #cde1f8;
    --warning-color: #ee9500;
    --warning-bk-color: #ffc15a;
    --warning-light-color: #ffdda5;
    --warning-light-bk-color: #fceacc;
    --danger-color: #db3b21;
    --danger-bk-color: #ff886d;
    --danger-light-color: #ffcabe;
    --danger-light-2-color: #ffefef;
    --danger-light-3-color: #fbdcd5;
    --dark-gray: #646f79;
    --white-color: #ffffff;
    --black-color: #000000;
    --light-gray-color: #f9fafc;
    --light-color: #e9e9ef;
    --placeholder-color: #9eafbe;
    --pagination-bk-color: #daf3ff;
    --pagination-text-color: #1e6de3;
    --piechart-color: #e16bf0;
    --piechart-donut-color: #41c1c1;
    --chart-progress-bar-color: #ff1e26;
    --inactive-bk-color: #db3b2140;
    --idle-bk-color: #fcecd1;

    --primary-extra-light-color: #f3fbff;

    --gray-bk-color: #f1f1f1;
    --light-gray: #f5f5f5;
    --tertiary-color: #1e6de3;
    --tertiary-bk-color: #daf3ff;
    --disabled-color: #ecebed;
    --disabled-dark-color: #a6a6a6;
    --border-color: #e5e7ed;
    --border-color-2: #cce1eb;
    --light-font-color: #b8b8b8;
    --lightgray-font-color: #b4b4b4;
    --gray-font-color: #646e78;
    --light-bk-color: #f9f9f9;
    --header-bk-color: #f8f8f8;
    --border-dark-color: #b5bac8;
    --light-border-color: #eeeeee;
    /* --alert-border-color: #f0f0f0; */
    --background-color: #f8f9fa;
    --sidebar-button-border-color: #e8f0fe;

    --sidebar-link-bk-color: #e8f0fe;
    --subline-text-color: #c0c0c0;
    --icon-bk-color: #ebf7fd;
    --sub-header-color: #0d0e10;
    --track-code-back: #ebf7fd;
    --track-code-border: #d9eaf2;

    --disable-font-color: #625e5e61;
    --black-font-color: #000000;

    --backdrop-color: #0000004d;
    --box-shadow-color: #0000001a;
    --arrow-bk-color: #f7f8f8;

    /**HOVER COLOR------------------------------------------------------------------- */
    --danger-hover: #eebeb6;
    --primary-hover: #f4f4f4;
    /* #b7e7ff; */
    --success-hover: #038b55;
    --warning-hover: #e59d23;

    /**FONTS------------------------------------------------------------------- */
    /* --bold-font: BRHendrix_Bold;
  --light-font: BRHendrix_Light;
  --regular-font: BRHendrix_Regular;
  --semi-bold-font: BRHendrix_Semi_Bold;
  --medium-font: BRHendrix_Medium;
  --bh-black-font: BRHendrix_Black; */
    /* 
    --bold-font: Roboto_Bold;
    --light-font: Roboto_Light;
    --regular-font: Roboto_Regular;
    --semi-bold-font: Roboto_Semi_Bold;
    --medium-font: Roboto_Medium;
    --bh-black-font: Roboto_Black; */

    --bold-font: Inter_Bold;
    --light-font: Inter_Light;
    --regular-font: Inter_Regular;
    --semi-bold-font: Inter_Semi_Bold;
    --medium-font: Inter_Medium;
    --bh-black-font: Inter_Black;
}

[dark-theme="dark"] {
    /* --background-color: #0000; */
    --primary-color: #1a73e8;
    --primary-bk-color: #1967d2;
    --primary-light-color: #f0f5fe;
    --secondary-color: #ffffff;
    --secondary-bk-color: #343a40;
    --secondary-light-color: #555555;
    --success-color: #3fbd61;
    --success-bk-color: #75f397;
    --success-light-color: #b0f9c4;
    --success-light-2-color: #e9f7f2;
    --success-light-3-color: #d9f2df;
    --info-color: #0367db;
    --info-bk-color: #64acff;
    --info-light-color: #9fccff;
    --info-light-2-color: #e6effa;
    --info-light-3-color: #cde1f8;
    --dark-gray: #646f79;
    --warning-color: #ee9500;
    --warning-bk-color: #ffc15a;
    --warning-light-color: #ffdda5;
    --warning-light-bk-color: #fceacc;
    --danger-color: #db3b21;
    --danger-bk-color: #ff886d;
    --danger-light-color: #ffcabe;
    --danger-light-2-color: #ffefef;
    --danger-light-3-color: #fbdcd5;
    --white-color: #000000;
    --light-gray-color: #f9fafc;
    --light-color: #e9e9ef;
    --placeholder-color: #9eafbe;
    --pagination-bk-color: #daf3ff;
    --pagination-text-color: #00abff;

    --gray-bk-color: #f1f1f1;
    --light-gray: #3e5060;
    --tertiary-color: #00abff;
    --tertiary-bk-color: #daf3ff;
    --disabled-color: #ecebed;
    --disabled-dark-color: #a6a6a6;
    --border-color: #464646;
    --light-font-color: #b8b8b8;
    --lightgray-font-color: #b4b4b4;
    --gray-font-color: #646e78;
    --light-bk-color: #f9f9f9;
    --header-bk-color: #f8f8f8;
    --light-border-color: #eeeeee;
    --piechart-color: #e88cf4;
    --piechart-donut-color: #5cd4d4;
    --chart-progress-bar-color: #ff1e26;
    /* --alert-border-color: #f0f0f0; */
    /* --background-color: #fdfdfd; */
    --background-color: #000000;
    --close-icon-bk-color: #dddddd;

    --sidebar-button-border-color: #e8ebf0;

    --sidebar-link-bk-color: #1a73e812;
    /* #00abff12; */
    --subline-text-color: #c0c0c0;
    --icon-bk-color: #ebf7fd;
    --sub-header-color: #565656;
    --track-code-back: #ebf7fd;
    --track-code-border: #d9eaf2;

    --disable-font-color: #8f8f8f61;
    --black-font-color: #fff;

    --backdrop-color: #3c3c3c80;
    --box-shadow-color: #b8b8b81a;

    /**HOVER COLOR------------------------------------------------------------------- */
    --danger-hover: #eebeb6;
    --primary-hover: #f0f5fe;
    /* #b7e7ff; */
    --success-hover: #038b55;
    --warning-hover: #e59d23;

    /**FONTS------------------------------------------------------------------- */
    --bold-font: BRHendrix_Bold;
    --light-font: BRHendrix_Light;
    --regular-font: BRHendrix_Regular;
    --semi-bold-font: BRHendrix_Semi_Bold;
    --medium-font: BRHendrix_Medium;
    --bh-black-font: BRHendrix_Black;
}
