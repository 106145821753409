.wrapper {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
}

/* LABEL -- START */
.label {
    color: var(--secondary-color);
    margin-bottom: 10px;
    display: inline-block;
    font-size: 14px;
    font-family: var(--medium-font);
}
/* LABEL -- END */

.wrapper.error.gs-simple-dropdown fieldset,
.wrapper.error.gs-simple-dropdown :global(.Mui-focused) > fieldset,
.wrapper.error.gs-simple-dropdown:hover fieldset {
    border-color: var(--danger-color) !important;
}

.wrapper.success.gs-simple-dropdown fieldset,
.wrapper.success.gs-simple-dropdown :global(.Mui-focused) > fieldset,
.wrapper.success.gs-simple-dropdown:hover fieldset {
    border-color: var(--success-color) !important;
}
/* INPUT FIELD -- END */

/* HELPER MESSAGE -- START */
.wrapper .helper-text {
    /* margin-top: 8px; */
    display: flex;
    align-items: center;
}
.wrapper .helper-text :not(:last-child) {
    margin-right: 4px;
}
.wrapper.error .helper-text > * {
    color: var(--danger-color);
}
.wrapper.error .helper-text > svg {
    width: 16px !important;
}
.wrapper.success .helper-text > * {
    color: var(--success-color);
}
/* HELPER MESSAGE -- END */

.success-icon {
    color: var(--success-color);
}

.gs-simple-dropdown {
    width: 100%;
}

.gs-simple-dropdown ::placeholder {
    color: var(--placeholder-color);
}

.gs-dropdown-popover-menu :global(.MuiPopover-paper .MuiMenu-list) {
    color: var(--light-font-color);
    font-family: var(--medium-font);
    font-size: 14px;
    padding: 0 !important;
}

.gs-simple-dropdown :global(.MuiSelect-select) {
    height: 44px;
    color: var(--dark-gray);
    font-size: 14px;
}

.gs-simple-dropdown :global(.Mui-focused) > fieldset,
.gs-simple-dropdown:hover fieldset {
    /* border-color: var(--primary-color) !important; */
    border-width: 1px !important;
}

.wrapper.error .gs-simple-dropdown fieldset,
.wrapper.error .gs-simple-dropdown :global(.Mui-focused) > fieldset,
.wrapper.error .gs-simple-dropdown:hover fieldset {
    border-color: var(--danger-color) !important;
}

.wrapper.success .gs-simple-dropdown fieldset,
.wrapper.success .gs-simple-dropdown :global(.Mui-focused) > fieldset,
.wrapper.success .gs-simple-dropdown:hover fieldset {
    border-color: var(--success-color) !important;
}

.gs-simple-dropdown fieldset {
    border-width: 1px !important;
    border-radius: 5px !important;
    border-color: var(--border-color) !important;
}

.gs-dropdown-popover-menu :global(.MuiPopover-paper) {
    max-height: 250px;
    padding: 0px !important;
    background-color: var(--white-color);
    box-shadow: 0px 5px 40px var(--box-shadow-color);
}

/* .gs-simple-dropdown-popup :global(.css-kk1bwy-MuiButtonBase-root-MuiMenuItem-root):hover {
    background-color: var(--primary-hover);
} */

.gs-simple-dropdown-popup :global(.css-kk1bwy-MuiButtonBase-root-MuiMenuItem-root) {
    display: flex;
    gap: 5px;
    color: var(--light-font-color);
    font-size: 16px;
}

.gs-simple-dropdown :global(.css-yf8vq0-MuiSelect-nativeInput) {
    opacity: 0;
    height: 100%;
    border: none;
    color: var(--secondary-light-color);
    font-size: 15px;
    padding-left: 16px;
}

.gs-simple-dropdown :global(.MuiSelect-nativeInput)::placeholder {
    color: var(--light-font-color);
    font-family: var(--regular-font);
}

.gs-simple-dropdown-popup :global(.css-kk1bwy-MuiButtonBase-root-MuiMenuItem-root.Mui-selected) {
    background-color: transparent;
    color: var(--primary-color);
}

.gs-simple-dropdown
    :global(
        .css-11u53oe-MuiSelect-select-MuiInputBase-input-MuiOutlinedInput-input.MuiSelect-select
    ) {
    z-index: 1;
}
.gs-simple-dropdown :global(.MuiSelect-iconOutlined) {
    color: var(--secondary-light-color);
    right: 13px;
}

.gs-dropdown-popover-menu :global(.MuiMenu-list .MuiMenuItem-root) {
    font-size: 15px;
    color: var(--secondary-light-color);
    font-family: var(--medium-font);
    text-transform: none;
}

.gs-dropdown-popover-menu :global(.MuiMenu-list .MuiMenuItem-root):hover {
    color: var(--primary-color);
    background: #fcfcfc;
}

.gs-dropdown-popover-menu :global(.MuiMenu-list .Mui-selected) {
    background: none;
    /* color: var(--primary-color); */
}
/* .gs-dropdown-popover-menu :global(.MuiMenuItem-root) svg {
    margin-right: 10px;
} */

.gs-dropdown-popover-menu :global(.MuiListSubheader-gutters) {
    position: sticky;
    top: 0px;
    background-color: var(--white-color);
    padding: 8px 8px 4px 8px;
}

.gs-dropdown-select {
    height: 44px;
    border-radius: 5px;
}
.select {
    font-size: 14px !important;
    color: var(--secondary-light-color) !important;
    padding: 0px 30px 0px 15px !important;
    text-transform: none;
    min-width: 70px !important;
}

.gs-dropdown-search-input {
    /* margin: 9px 15px; */
    border: 1px solid var(--border-color);
    border-radius: 5px;
    padding: 10px 13px;
    display: flex;
    align-items: center;
    gap: 10px;
}

.gs-dropdown-search-input input {
    border: none;
    font-size: 14px;
    color: var(--secondary-light-color);
    width: 100%;
    background-color: var(--white-color);
}
.gs-dropdown-search-input input::placeholder {
    font-size: 14px;
    color: var(--light-font-color);
}

.gs-dropdown-content {
    max-height: 177px;
    overflow: auto;
}
