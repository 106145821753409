/* @font-face {
    font-family: "BRHendrix_Regular";
   src: url("../Fonts/BRHendrix-Regular.ttf") format("truetype");
  }
  @font-face {
    font-family: "BRHendrix_Semi_Bold";
    src: url("../Fonts/BRHendrix-SemiBold.ttf") format("truetype");
  }
  @font-face {
    font-family: "BRHendrix_Bold";
    src: url("../Fonts/BRHendrix-Bold.ttf") format("truetype");
  }
  @font-face {
    font-family: "BRHendrix_Light";
    src: url("../Fonts/BRHendrix-Light.ttf") format("truetype");
  }
  @font-face {
    font-family: "BRHendrix_Medium";
    src: url("../Fonts/BRHendrix-Medium.ttf") format("truetype");
  }
  @font-face {
    font-family: "BRHendrix_Black";
    src: url("../Fonts/BRHendrix-Black.ttf") format("truetype");
  }
  */

@font-face {
    font-family: "Roboto_Regular";
    src: url("../Fonts/Roboto-Regular.ttf") format("truetype");
}
@font-face {
    font-family: "Roboto_Semi_Bold";
    src: url("../Fonts/Roboto-Medium.ttf") format("truetype");
}
@font-face {
    font-family: "Roboto_Bold";
    src: url("../Fonts/Roboto-Bold.ttf") format("truetype");
}
@font-face {
    font-family: "Roboto_Light";
    src: url("../Fonts/Roboto-Light.ttf") format("truetype");
}
@font-face {
    font-family: "Roboto_Medium";
    src: url("../Fonts/Roboto-Medium.ttf") format("truetype");
}
@font-face {
    font-family: "Roboto_Black";
    src: url("../Fonts/Roboto-Black.ttf") format("truetype");
}
