/* .gs-login-page {
  background-color: var(--pagination-bk-color);
} */

.gs-login-page {
    /* background: var(--pagination-bk-color); */
    background: url("/public/image/LoginMainImg.png"),
        linear-gradient(41.42deg, #e6f6fd -29.72%, #fbfdff 30.42%, #ffffff 75.24%);
    background-size: 100% 100%;
    background-repeat: no-repeat;
    background-position: left bottom;
    overflow-y: auto;
    height: 100vh;
    background-size: cover;
}

.gs-login-fields {
    /* height: 100%;
  width: 85%;
  background-color: var(--white-color);
  display: flex;
  flex-direction: column;
  padding: 20px 50px;
  gap: 10px;
  overflow-y: auto;
  justify-content: space-between; */
    width: 80%;
    background-color: var(--white-color);
    display: flex;
    flex-direction: column;
    padding: 30px 35px;
    gap: 10px;
    overflow-y: auto;
    justify-content: space-between;
    box-shadow: 0px 4px 60px rgba(0, 0, 0, 0.07);
    border-radius: 20px;
    max-width: 80%;
    margin: 30px 0;
}
.gs-login-banner {
    height: 100vh;
    display: flex;
    flex-direction: column;
}

.gs-login-social-wrapper p {
    margin-top: 5px;
}
.gs-login-header {
    /* margin-top: 5px; */
    display: none;
}
.gs-login-social {
    display: flex;
    gap: 20px;
    flex-direction: row;
    flex-wrap: nowrap;
    margin: 10px 0;
}
.gs-login-social div {
    width: 100%;
}
.gs-login-social-item {
    border: 1px solid var(--border-color);
    height: 50px;
    display: flex;
    gap: 15px;
    border-radius: 5px;
    align-items: center;
    justify-content: center;
    cursor: pointer;
}
.gs-login-social-item:hover,
.gs-login-social-item:focus {
    background-color: var(--light-gray-color);
    border: 1px solid var(--primary-color);
}
.gs-login-social-item p {
    font-size: 14px;
    line-height: 18px;
    color: var(--secondary-color);
}
.gs-login-form {
    display: flex;
    flex-direction: column;
    height: 88%;
    gap: 10px;
    /* justify-content: space-between; */
}
.gs-login-header-text {
    display: flex;
    flex-direction: column;
    gap: 5px;
}
.gs-login-header-text h1 {
    /* font-size: 27px;
  font-weight: 500;
  color: var(--secondary-color); */
    color: var(--secondary-color);
    font-style: normal;
    font-weight: 400;
    font-size: 30px;
    line-height: 39px;
}
.gs-login-or {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 10px;
    /* margin: 14px 0; */
    margin: 5px 0 5px 0;
}
.gs-login-or-line {
    width: 40%;
    height: 1px;
    background-color: var(--border-color);
}
.gs-login-or p {
    text-align: center;
    color: var(--secondary-light-color);
    width: 150px;
}

.gs-login-form-fields {
    display: flex;
    flex-direction: column;
    gap: 20px;
    margin: 0px 0 0 0;
}

.gs-login-form-fields-forgot {
    display: flex;
    justify-content: space-between;
    align-items: center;
}
.gs-login-form-fields-forgot-checkbox {
    display: flex;
    align-items: center;
    gap: 5px;
}
.gs-login-form-fields-otp-login {
    color: var(--danger-color);
    font-size: 14px;
}
.gs-login-form-fields-forgot-checkbox input {
    background: #ffffff;
    border: 1px solid #f0f0f0;
    border-radius: 5px;
    height: 18px;
    width: 18px;
}
.forget-text {
    font-size: 14px;
    line-height: 18px;
    color: var(--primary-color);
    cursor: pointer;
}

.gs-login-form-signup-button {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 3px;
    height: 25px;
    margin-top: 5px;
}
.signup-signin-text {
    font-size: 14px !important;
    line-height: 18px !important;
    color: var(--primary-color) !important;
    cursor: pointer !important;
    padding: 0 0 0 5px !important;
    text-transform: inherit !important;
}
.gs-login-footer {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 5px;
    font-size: 10px;
    flex-direction: column;
    margin-top: 10px;
}
.gs-login-footer p {
    font-size: 13px;
    color: var(--secondary-light-color);
    text-align: center;
}

/*-----------------FORGOT PASSWORD  CSS--------------------*/
.gs-forgot-password-from {
    display: flex;
    flex-direction: column;
    gap: 10px;
    height: 88%;
}

.gs-forgot-password-header-text {
    display: flex;
    flex-direction: column;
    gap: 10px;
}

.gs-forgot-password-header-text h1 {
    font-size: 27px;
    color: var(--secondary-color);
    font-weight: 500;
}

.otp-fields {
    height: 55px;
    width: 55px;
}
.otp-form {
    gap: 15px;
}

:global(.gs-password-icon svg) {
    font-size: 20px;
    color: var(--secondary-light-color);
}

:global(.gs-password-icon) {
    right: 5px;
    position: relative !important;
}

:global(.gs-password-icon:hover) {
    background-color: transparent !important;
}

.gs-registration-form :global(.gs-primary-button.MuiButton-root) {
    height: 50px;
}

/*------------------------------invitation-link-----------------------------*/
.gs-invitation-link-page {
    display: flex;
    align-self: center;
    width: 100vw;
    height: 100vh;
    justify-content: center;
    align-items: center;
    background-color: var(--border-color);
}
.gs-invitation-link-card {
    background-color: var(--white-color);
    border-radius: 10px;
    display: flex;
    width: 600px;
    padding: 40px 40px;
    flex-direction: column;
    align-items: center;
    gap: 30px;
    align-content: center;
}
.gs-invitation-link-card .gs-logo-wrap {
    display: flex;
    flex-direction: column;
    align-items: center;
}
.gs-invitation-link-card .gs-logo-wrap img {
    height: 80px;
    width: 70px;
}
.gs-invitation-link-card .gs-logo-wrap h2 {
    font-weight: normal;
    font-size: 20px;
    margin-top: 15px;
}
.gs-invitation-link-card .gs-invitation-name {
    line-height: 23px;
    font-size: 18px;
}

.gs-invitation-link-card .gs-invitation-desc {
    margin-top: 15px;
    font-weight: 400;
    font-size: 15px;
    line-height: 19px;
}
.gs-invitation-link-card .gs-invitation-desc-note {
    font-weight: 400;
    font-size: 15px;
    text-align: center;
    line-height: 20px;
}
.gs-invitation-link-card .gs-invitation-desc-note p {
    font-weight: 400;
    font-size: 15px;
    line-height: 19 px;
    color: var(--primary-color);
}
.gs-invitation-link-card .gs-invitation-desc span {
    color: var(--primary-color);
}
.gs-invitation-link-card .gs-invitation-footer-tetx span {
    color: var(--primary-color);
}
.gs-invitation-link-page .gs-invitation-footer-text {
    font-size: 15px;
    line-height: 20px;
    color: var(--black-color);
    font-family: var(--medium-font);
}

.gs-invitation-link-page .gs-invitation-footer-text span {
    color: var(--primary-color);
}

/* INPUT FIELD -- START */
.login-text-field label {
    line-height: 1.8em;
    font-size: 14px;
    color: var(--secondary-light-color);
}
.login-text-field :global(.css-1sumxir-MuiFormLabel-root-MuiInputLabel-root.Mui-focused) {
    color: var(--primary-color);
}
.login-text-field :global(.MuiInputLabel-shrink) {
    color: var(--primary-color);
    border-width: 1px;
}
.login-text-field input {
    color: var(--secondary-light-color);
    padding: 12px 14px;
    font-size: 14px;
}

.login-text-field :global(.MuiOutlinedInput-notchedOutline) {
    border-color: var(--border-color);
}

.login-text-field :global(.Mui-focused) > fieldset,
.login-text-field:hover fieldset {
    border-width: 1px !important;
    border-color: var(--primary-color) !important;
}
.wrapper.error .login-text-field :global(.MuiInputLabel-shrink) {
    color: var(--danger-color) !important;
}

.wrapper.error .login-text-field fieldset,
.wrapper.error .login-text-field :global(.Mui-focused) > fieldset,
.wrapper.error .login-text-field:hover fieldset {
    border-color: var(--danger-color) !important;
}

.wrapper.success .login-text-field :global(.MuiInputLabel-shrink) {
    color: var(--success-color) !important;
}
.wrapper.success .login-text-field fieldset,
.wrapper.success .login-text-field :global(.Mui-focused) > fieldset,
.wrapper.success .login-text-field:hover fieldset {
    border-color: var(--success-color) !important;
}

/* INPUT FIELD -- END */
/* HELPER MESSAGE -- START */
.wrapper .helper-text {
    margin-top: 5px;
    display: flex;
    align-items: center;
}
.wrapper .helper-text :not(:last-child) {
    margin-right: 4px;
}
.wrapper.error .helper-text > * {
    color: var(--danger-color);
    font-size: 13px;
}
.wrapper.error .helper-text > svg {
    width: 16px !important;
}
.wrapper.success .helper-text > * {
    color: var(--success-color);
}
/* HELPER MESSAGE -- END */

.gs-login-banner-rightcontainer {
    /* width: 50%; */
    background: transparent;
}
.gs-login-banner-leftcontainer {
    width: 50%;
    color: gray;
    height: auto !important;
}

/* Terms of services  */
.gs-terms-maindiv {
    background-color: var(--primary-extra-light-color);
    overflow: auto;
}

.gs-terms-service {
    padding: 32px;
    width: 978px;
    background-color: #fff;
    margin: 0 auto;
    min-width: 978px;
    max-width: 1680px;
    position: relative;
}

.gs-terms-content {
    margin: 40px 0;
}

.gs-terms-content h1 {
    font-size: 22px;
    font-weight: 400;
    margin: 15px 0;
}
.gs-terms-content h3 {
    font-size: 18px;
    font-weight: 500;
    margin: 15px 0;
}

.gs-terms-content p {
    line-height: 22px;
    margin-bottom: 20px;
    display: block;
    margin-left: 30px;
}

.gs-terms-content span {
    float: left;
    font-size: 14px;
    margin-top: 2px;
}
.gs-terms-header {
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.gs-login-page-otp-login {
    font-size: 14px;
}

/*-----------------MEDIA CSS--------------------*/

@media screen and (max-width: 1600px) {
    .gs-login-or p {
        width: 165px;
    }
}
@media screen and (max-width: 1366px) {
    /* .gs-login-social{
    flex-direction: column;
    gap: 13px;
  } */
    .gs-login-form {
        height: 100%;
        gap: 0px;
    }
    .gs-login-form-fields {
        gap: 15px;
        /* margin: 5px 0 0 0; */
    }
    .gs-forgot-password-from {
        gap: 15px;
        /* padding-top: 10px; */
    }
    .gs-login-social {
        margin-top: 15px;
    }
    .gs-login-or p {
        width: 170px;
    }
    .gs-login-fields {
        /* height: 100vh; */
        max-width: 90%;
        width: 100%;
    }
    /* .gs-login-banner {
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        width: 50%;
    } */
}

@media screen and (max-width: 1280px) {
    .gs-login-or p {
        width: 190px;
    }
    .gs-login-or {
        margin: 10px 0 15px;
    }
}
@media screen and (max-width: 1180px) {
    .gs-login-social-item p {
        display: block;
        width: 60px;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
    }
}

/* @media screen and (max-width: 1199px) {
  .gs-login-social {
    gap: 10px;
  }
  .gs-login-social-item {
    gap: 5px;
  }
} */
@media screen and (max-width: 1099px) {
    .gs-login-fields {
        width: 100%;
        padding: 20px 30px;
    }
    .gs-login-header img {
        height: 45px;
    }
    .gs-login-header-text h1 {
        font-size: 24px;
    }
    .gs-login-header-text {
        gap: 0;
    }
    /* .gs-login-social {
    margin-top: 20px;
  } */
}

@media screen and (max-width: 1024px) {
    .gs-login-social-item p {
        display: block;
        width: 60px;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
    }
    .gs-login-or p {
        width: 200px;
    }
}
@media screen and (max-width: 978px) {
    .gs-terms-service {
        min-width: 100%;
        max-width: 100%;
    }
}

@media screen and (max-width: 949px) {
    .gs-login-or p {
        width: 210px;
    }
}

@media screen and (max-width: 899px) {
    .gs-login-banner {
        display: none;
    }
    .gs-login-fields {
        width: 100%;
        height: 100vh;
        padding: 20px 30px;
    }
    .gs-login-social-item p {
        display: contents;
    }
    .gs-login-fields {
        width: 100%;
        max-width: 100%;
        margin: 0;
        border-radius: 0px;
        box-shadow: none;
    }
    .gs-login-form {
        /* height: 85%; */
        gap: 10px;
    }
    .gs-login-header {
        display: block;
    }
    .gs-login-or p {
        width: 180px;
    }
    .gs-otplogin-page .gs-login-fields {
        height: 92vh;
    }
}

@media (max-width: 870px) {
    /* .gs-login-social {
    flex-direction: column;
  }
  .gs-login-or-line {
    width: 25%;
  } */

    .gs-login-or {
        margin: 5px 0;
    }
}

@media screen and (max-width: 768px) {
    .gs-login-social {
        flex-direction: row;
    }
    .gs-login-banner-rightcontainer {
        display: none;
    }
    .gs-login-banner-leftcontainer {
        width: 100%;
    }
}

@media screen and (max-width: 500px) {
    .gs-login-social {
        flex-direction: column;
        gap: 15px;
    }

    .gs-login-or p {
        width: 210px;
    }
}
@media screen and (max-width: 410px) {
    .gs-login-or p {
        /* width: 180px; */
        width: 210px;
    }
    .gs-login-or-line {
        width: 20%;
    }
    .gs-login-or {
        gap: 25px;
    }
}

@media screen and (max-width: 380px) {
    .gs-login-form {
        height: 100%;
    }
    .gs-login-form-signup-button {
        flex-direction: column;
        gap: 10px;
        margin-top: 20px;
    }
    .gs-login-footer {
        margin-top: 40px;
    }
    .gs-login-form-fields-forgot {
        flex-direction: column;
        gap: 10px;
    }
}
