.date-picker :global(.css-gztimk-MuiPaper-root-MuiPickersPopper-paper) {
    box-shadow: 0px 7px 64px rgba(0, 0, 0, 0.07);
    border-radius: 6px;
}

/* DAY -- START */
.date-picker :global(.css-bkrceb-MuiButtonBase-root-MuiPickersDay-root:hover) {
    background-color: var(--tertiary-bk-color);
    color: var(--primary-color);
}

.date-picker :global(.css-bkrceb-MuiButtonBase-root-MuiPickersDay-root.Mui-selected),
.date-picker :global(.css-bkrceb-MuiButtonBase-root-MuiPickersDay-root.Mui-selected:hover),
.date-picker :global(.css-bkrceb-MuiButtonBase-root-MuiPickersDay-root.Mui-selected:focus) {
    background-color: var(--primary-color);
    color: var(--white-color);
}

.date-picker :global(.css-195y93z-MuiButtonBase-root-MuiPickersDay-root.Mui-selected),
.date-picker :global(.css-195y93z-MuiButtonBase-root-MuiPickersDay-root.Mui-selected:hover),
.date-picker :global(.css-195y93z-MuiButtonBase-root-MuiPickersDay-root.Mui-selected:focus) {
    background-color: var(--primary-color);
}

.date-picker :global(.MuiDayPicker-weekContainer) > *:first-child:not(:global(.Mui-selected)) {
    color: red !important;
}

/* DAY -- END */

/* YEAR -- START */
.date-picker :global(.css-3eghsz-PrivatePickersYear-button:focus),
.date-picker :global(.css-3eghsz-PrivatePickersYear-button:hover) {
    background-color: var(--tertiary-bk-color);
    color: var(--primary-color);
}

.date-picker :global(.css-3eghsz-PrivatePickersYear-button.Mui-selected),
.date-picker :global(.css-3eghsz-PrivatePickersYear-button.Mui-selected:hove)r,
.date-picker :global(.css-3eghsz-PrivatePickersYear-button.Mui-selected:focus) {
    background-color: var(--primary-color);
    color: var(--white-color);
}

/* YEAR -- END */

/* ARROW -- START */
.date-picker-arrow {
    box-shadow: 0px 7px 20px rgba(0, 0, 0, 0.07) !important;
    background-color: var(--white-color) !important;
    border-radius: 6px !important;
}
/* ARROW -- END */

.date-picker-icon {
    margin-right: -7px !important;
}
.date-picker-icon svg {
    font-size: 20px;
}
.MuiDayPicker-weekContainer .MuiPickersDay-root {
    line-height: 22px;
}
