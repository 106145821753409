.alert {
    width: 100% !important;
    max-width: 440px !important;
    background-color: var(--background-color) !important;
    border-radius: 5px !important;
    border: 1px solid var(--border-color) !important;
    box-shadow: none !important;
    padding: 30px !important;
}

.content {
    padding: 0 !important;
    display: flex;
    flex-direction: column;
}

.icon {
    height: 50px;
    width: 50px;
    border-radius: 8px;
    background-color: var(--warning-light-bk-color);
    display: flex;
    align-items: center;
    justify-content: center;
    margin: 0 auto;
    margin-bottom: 15px;
}

.icon svg {
    font-size: 28px;
    color: var(--warning-color);
}

.title {
    text-align: center !important;
    font-size: 18px !important;
    color: var(--secondary-bk-color) !important;
    font-family: var(--semi-bold-font) !important;
}

.helper-text {
    margin-top: 7px !important;
    font-size: 14px !important;
    text-align: center !important;
    color: var(--secondary-light-color) !important;
    font-family: var(--medium-font) !important;
}

.divider {
    margin: 30px 0 20px 0 !important;
}

.buttons-wrapper {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 20px;
}

.buttons-wrapper button {
    width: auto !important;
    padding: 9px 40px !important;
}
