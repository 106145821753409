.gs-kendo-loader-wrapper {
    /* height: 100vh;
  width: 100vw; */
    /* background-color: rgba(255, 255, 255, 0.418); */
    position: absolute;
    top: 0;
    z-index: 99999;
    display: flex;
    justify-content: center;
    align-items: center;
    left: 0;
}

.table-linear-activity-open {
    overflow: hidden;
    width: calc(100vw - 260px);
    height: 2px;
    background-color: var(--primary-light-color);
    position: absolute;
    top: 58px;
    z-index: 19;
    left: 260px;
}

.indeterminate:after {
    content: "";
    position: absolute;
    height: 100%;
    background-color: var(--primary-color);
    animation: indeterminate_second 1s infinite ease-in;
}

@keyframes indeterminate_first {
    0% {
        left: -90%;
        width: 100%;
    }
    100% {
        left: 100%;
        width: 10%;
    }
}

@keyframes indeterminate_second {
    0% {
        left: -130%;
        width: 100%;
    }
    100% {
        left: 110%;
        width: 10%;
    }
}

.table-linear-activity-close {
    overflow: hidden;
    width: calc(100vw - 80px);
    height: 2px;
    background-color: var(--primary-light-color);
    position: absolute;
    top: 58px;
    z-index: 19;
    left: 80px;
}

/* ----------------------drawer loader css -------------*/

.gs-drawer-loader-wrapper {
    display: flex;
    justify-content: center;
    align-items: center;
}

.drawer-linear-activity-open {
    overflow: hidden;
    width: 100%;
    height: 2px;
    background-color: var(--primary-light-color);
    position: absolute;
    /* z-index: 19; */
}

/* ----------------------button loader css -------------*/

.gs-button-loader {
    /* display: inline-block; */
    position: relative;
    width: 80px;
    display: flex;
    height: 13px;
    align-items: center;
}
.gs-button-loader div {
    position: absolute;
    width: 13px;
    height: 13px;
    border-radius: 50%;
    background: #fff;
    animation-timing-function: cubic-bezier(0, 1, 1, 0);
}
.gs-button-loader div:nth-child(1) {
    left: 8px;
    animation: gs-button-loader1 0.6s infinite;
}
.gs-button-loader div:nth-child(2) {
    left: 8px;
    animation: gs-button-loader2 0.6s infinite;
}
.gs-button-loader div:nth-child(3) {
    left: 32px;
    animation: gs-button-loader2 0.6s infinite;
}
.gs-button-loader div:nth-child(4) {
    left: 56px;
    animation: gs-button-loader3 0.6s infinite;
}
@keyframes gs-button-loader1 {
    0% {
        transform: scale(0);
    }
    100% {
        transform: scale(1);
    }
}
@keyframes gs-button-loader3 {
    0% {
        transform: scale(1);
    }
    100% {
        transform: scale(0);
    }
}
@keyframes gs-button-loader2 {
    0% {
        transform: translate(0, 0);
    }
    100% {
        transform: translate(24px, 0);
    }
}

.register-browser-wrap {
    position: absolute;
    top: 50%;
    left: 50%;
    gap: 10px;
    display: flex;
    transform: translate(-50%, -50%);
    flex-direction: column;
    align-items: center;
}
