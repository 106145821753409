.gs-upload-button input[type="file"] {
    display: none;
}

.gs-custom-upload-button {
    border: 0.1px solid var(--border-color);
    font-family: var(--medium-font);
    background-color: var(--white-color);
    padding: 10px 15px;
    cursor: pointer;
}

.language_modal_form {
    display: flex;
    flex-direction: column;
}

.language_modal_form img {
    height: 27px;
    width: 30px;
}

.gs-upload-button {
    /* width: 145px; */
    display: flex;
    flex-direction: row;
}

.gs-upload-button label {
    /* border-radius: 5px;
  padding: 8px 20px;
  height: 36px;
  letter-spacing: 0.2px;
  border: solid 0.1px;
  transition: all 0.3s;
  background-color: var(--white-color);
  text-transform: capitalize;
  box-shadow: 0px 4px 10px 0px rgb(0 0 0 / 6%);
  min-width: 100px;
  color: var(--secondary-light-color);
  border-color: var(--secondary-light-color);
  font-size: 14px;
  display: flex;
  justify-content: center;
  align-items: center; */
    background-color: var(--white-color);
    border-width: 0.1px;
    border-style: solid;
    border-image: initial;
    border-color: var(--primary-color);
    border-radius: 5px;
    /* box-shadow: rgba(0, 0, 0, 0.06) 0px 4px 10px 0px; */
    color: var(--primary-color);
    font-size: 14px;
    height: 36px;
    letter-spacing: 0.2px;
    min-width: 100px;
    padding: 8px 20px;
    text-transform: capitalize;
    transition: all 0.3s ease 0s;
    align-items: center;
    display: flex;
    /* flex-direction: column; */
    justify-content: center;
}

.gs-upload-button label:hover {
    /* background-color: var(--secondary-light-color);
  color: var(--white-color);
  cursor: pointer; */
    background-color: var(--sidebar-link-bk-color);
    border-color: var(--primary-bk-color);
    color: var(--primary-bk-color);
    cursor: pointer;
}

.gs-upload-image-preview {
    width: 10px;
}

.helper-text {
    text-align: center;
    font-style: italic;
    color: var(--danger-color);
    font-size: 12px;
    font-style: italic;
    letter-spacing: 0.2px;
    font-weight: 400;
}

.gs-button-loader-bubble {
    height: 46px !important;
    width: 46px !important;
}

.gs-image {
    height: auto;
    border: dashed 1.5px #e9e9ef;
    background-color: white;
    border-radius: 5px;
    width: 100%;
    height: 80%;
    margin-bottom: 20px;
    display: flex;
    flex-direction: column;
}

.gs-title {
    font-size: 15px;
    margin-top: 5px;
    margin-bottom: 10px;
}

.gs-image-description {
    display: flex;
    justify-content: center;
    font-size: 12px;
    text-align: center;
    padding: 0px 5px;
}

.gs-image-preview {
    height: 50px;
    display: table;
    margin: 25px auto 20px;
    justify-content: center;
    border-radius: 5px;
    position: relative;
    padding: 5px;
    background-color: #ffffff;
}

.gs-extended-upload-button-wrapper {
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 20px;
    flex-direction: column;
    gap: 10px;
}

.gs-General-button-div {
    display: flex;
    padding-top: 20px;
    justify-content: flex-end;
}

.gs-button-loader-bubble {
    height: 46px !important;
    width: 46px !important;
}
