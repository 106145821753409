.wrapper {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
}

/* LABEL -- START */
.label {
    color: var(--secondary-color);
    margin-bottom: 10px;
    display: inline-block;
    font-size: 14px;
    font-family: var(--medium-font) !important;
}

/* LABEL -- END */

/* INPUT FIELD -- START */
.text-field input {
    color: var(--secondary-light-color);
    height: 44px;
    padding: 0 15px;
    border-radius: 5px;
    font-size: 14px;
    text-overflow: ellipsis;
    background-color: var(--white-color) !important;
    font-family: var(--medium-font);
}

.text-field input:-webkit-autofill {
    -webkit-text-fill-color: var(--secondary-light-color) !important;
    -webkit-box-shadow: 0 0 0 30px var(--white-color) inset;
}

.text-field input:disabled {
    color: var(--secondary-light-color);
    -webkit-text-fill-color: rgba(218, 39, 39, 0.38);
    -webkit-text-fill-color: var(--disable-font-color);
}

.text-field input::placeholder,
.text-field textarea::placeholder {
    color: var(--placeholder-color);
    font-family: var(--regular-font);
}

.text-field :global(.MuiInputBase-inputMultiline) {
    font-size: 14px;
    color: var(--secondary-light-color);
    background-color: var(--white-color);
}

.text-field :global(.MuiInputBase-multiline) {
    padding: 11px 15px 15px 15px;
    background-color: var(--white-color);
}

.text-field fieldset {
    border-width: 1px !important;
    border-radius: 5px !important;
    border-color: var(--border-color) !important;
}

.text-field :global(.Mui-focused) > fieldset,
.text-field:hover fieldset {
    border-color: var(--primary-color) !important;
}

.wrapper.error .text-field fieldset,
.wrapper.error .text-field :global(.Mui-focused) > fieldset,
.wrapper.error .text-field:hover fieldset {
    border-color: var(--danger-color) !important;
}

.wrapper.success .text-field fieldset,
.wrapper.success .text-field :global(.Mui-focused) > fieldset,
.wrapper.success .text-field:hover fieldset {
    border-color: var(--success-color) !important;
}

/* INPUT FIELD -- END */

/* HELPER MESSAGE -- START */
.wrapper .helper-text {
    margin-top: 5px;
    display: flex;
    align-items: center;
}

.wrapper .helper-text :not(:last-child) {
    margin-right: 4px;
}

.wrapper.error .helper-text > * {
    color: var(--danger-color);
}

.wrapper.error .helper-text > svg {
    width: 16px !important;
}

.wrapper.success .helper-text > * {
    color: var(--success-color);
}

/* HELPER MESSAGE -- END */

.success-icon {
    color: var(--success-color);
}

.helper-text p {
    font-size: 14px;
    font-style: normal;
    letter-spacing: 0.2px;
    font-weight: 400;
}
