@value search-input from "./SearchInput.module.css";

.wrapper:global(.Mui-focused) .search-input {
    border-radius: 8px 8px 0px 0px;
    box-shadow: none !important;
    border-color: var(--border-color);
}

.wrapper .search-input :global(.MuiInputBase-root) {
    padding: 0px 15px !important;
}
.wrapper .search-input :global(.MuiInputBase-root):global(.MuiInputBase-sizeSmall) {
    padding: 0px 14px !important;
}

.wrapper .search-input :global(.MuiInputBase-root) input {
    font-size: 14px;
    color: var(--secondary-light-color);
    height: 44px;
    padding: 0 15px !important;
}

.wrapper:global(.Mui-focused) + :global(.MuiAutocomplete-popper) {
    margin: 5px 0 !important;
    border: 1px solid rgba(0, 0, 0, 0.23);
    border-radius: 8px;
    overflow: hidden;
}
