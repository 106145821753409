/*?----------------------- CARDS COMPONENTS -----------------------*/
.gs-cards {
    border-radius: 5px !important;
    box-shadow: none !important;
    border: 1px solid var(--border-color) !important;
    background-color: #fff !important;
}
.gs-cards-content {
    padding-bottom: 24px;
    font-family: var(--medium-font);
    font-size: 14px;
    font-weight: 400;
    font-style: normal;
    line-height: 24px;
    padding: 10px 20px 10px 20px !important;
}

.gs-cards :global(.MuiCardActions-spacing) {
    padding: 20px;
    gap: 10px;
}

.card {
    border: 1px solid var(--border-color);
    border-radius: 5px;
    width: 100%;
    overflow: hidden;
    display: flex;
    flex-direction: column;
}

.body {
    /* background-color: var(--white-color); */
    background-color: var(--white-color);
    flex-grow: 1;
}

.header {
    background-color: var(--white-color);
    padding: 20px 20px 0 20px;
    /* border-bottom: 1px solid var(--border-color); */
}
