.gs-sidebar {
    display: flex;
    height: calc(100vh - 60px);
    border-right: 1px solid var(--border-color);
}

.gs-sidebar-left {
    width: 70px;
    background: var(--white-color);
    /* border-right: 1px solid var(--border-color); */
    display: flex;
    flex-direction: column;
    overflow: hidden auto;
    justify-content: space-between;
}

.gs-sidebar-right {
    overflow: hidden auto;
    width: 180px;
    background: var(--white-color);
    /* background-color: white; */
    transition: all 0.3s;
    display: flex;
    flex-direction: column;
    white-space: nowrap;
    transition: all 0.3s;
    padding-top: 10px;
    border-left: 1px solid var(--border-color);
}

.gs-sidebar-right.hide {
    width: 0;
    transition: all 0.3s;
}

.gs-sidebar-button.hide {
    left: 59px;
    transition: all 0.3s;
    transform: rotate(180deg);
}

.gs-sidebar-button {
    cursor: pointer;
    border-radius: 50%;
    position: absolute;
    z-index: 2;
    top: 28px;
    left: 237px;
    transition: all 0.3s;
    border: 1px solid var(--sidebar-button-border-color);
    background-color: var(--white-color);
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 22px;
    color: var(--secondary-light-color);
    padding: 5px;
}

.gs-sidebar-container {
    position: relative;
}

.gs-sidebar-left-link {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    color: var(--secondary-light-color);
    font-size: 12px;
    gap: 6px;
    /* padding: 8px 15px 8px 12px; */
    padding: 10px 15px 7px 12px;
    font-family: var(--medium-font);
    text-decoration: none;
    transition: color 0.3s;
    border-left: 4px solid transparent;
    text-transform: capitalize;
    /* margin-bottom: 5px; */
}

.gs-sidebar-left-link.active {
    background-color: var(--sidebar-link-bk-color) !important;
    border-left: 4px solid var(--primary-color);
    color: var(--primary-color);
    transition: color 0.3s;
    font-family: var(--medium-font);
}

.gs-sidebar-left-link:active svg:not(:global(.secondary-light-icon-stroke)) path {
    fill: var(--primary-color);
}

.gs-sidebar-left-link:active svg:global(.secondary-light-icon-stroke) path {
    stroke: var(--primary-color);
}

.gs-sidebar-left-link.active svg:not(:global(.secondary-light-icon-stroke)) path {
    fill: var(--primary-color);
}

.gs-sidebar-left-link.active svg:global(.secondary-light-icon-stroke) path {
    stroke: var(--primary-color);
}

.gs-sidebar-left-link:active {
    color: var(--primary-color);
}

.gs-sidebar-left-link.active svg:hover {
    color: var(--primary-color);
}

.gs-sidebar-left-link:active {
    background-color: var(--sidebar-link-bk-color);
    border-left: 4px solid var(--primary-color);
    color: var(--primary-color);
}

.gs-sidebar-left-link:hover {
    background-color: var(--background-color);
}

/* .gs-sidebar-left-link svg {
    width: 18px;
} */

.gs-sidebar-left-link svg path {
    fill: var(--secondary-light-color);
}

.gs-sidebar-left-link:nth-child(5) svg {
    width: 22px;
}

.gs-sidebar-left-link:active svg {
    fill: var(--primary-color) !important;
}

.gs-sidebar-left-link.active svg path {
    fill: var(--primary-color) !important;
}

/* .gs-sidebar-left-link:hover svg path {
    fill: var(--secondary-color) !important;
} */

.gs-sidebar-left-link.active svg path {
    fill: var(--primary-color) !important;
}

/* .gs-sidebar-left-link:active,
.gs-sidebar-left-link:hover {
  background-color: var(--sidebar-link-bk-color);
  border-left: 3px solid var(--primary-color);
  color: var(--primary-color);
}

.gs-sidebar-left-link:active svg,
.gs-sidebar-left-link:hover svg {
  fill: var(--primary-color) !important;
}

.gs-sidebar-left-link.active svg {
  fill: var(--primary-color) !important;
}
.gs-sidebar-left-link.active svg path {
  fill: var(--primary-color) !important;
} */

.gs-sidebar-right-link {
    transition: all 0.3s;
    text-decoration: none;
    color: var(--secondary-light-color);
    padding: 11px 19px 11px 22px;
    font-family: var(--medium-font);
    font-size: 14px;
    border-left: 3px solid transparent;
    display: block;
    /* overflow: hidden; */
    text-overflow: ellipsis;
}

.gs-sidebar-right-link:active,
.gs-sidebar-right-link:hover {
    transition: all 0.3s;
    background-color: var(--primary-hover);
    /* border-left: 3px solid var(--primary-color); */
    color: var(--secondary-light-color);
    /* background-color: var(--sidebar-link-bk-color);
  border-left: 3px solid var(--primary-color);
  color: var(--primary-color);
  text-overflow: clip; */
}

.gs-sidebar-right-link.active {
    transition: all 0.3s;
    background-color: var(--sidebar-link-bk-color);
    border-left: 3px solid var(--primary-color);
    color: var(--primary-color);
    text-overflow: clip;
}

.gs-sidebar-separator {
    border-bottom: 1px solid var(--border-color);
    margin: 10px 0;
}
.gs-submenu-wrap {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 5px 16px 3px 16px;
    margin-bottom: 10px;
}

.gs-submenu-label {
    font-weight: 400;
    font-size: 13px;
    color: var(--lightgray-font-color);
    text-transform: uppercase;
    /* padding: 5px 16px 3px 16px; */
    margin-bottom: 10px;
    letter-spacing: 0.2px;
    width: 175px;
    white-space: nowrap;
    /* overflow: hidden; */
    text-overflow: ellipsis;
}

@media screen and (max-width: 1280px) {
    .gs-submenu-label {
        font-weight: 400;
        font-size: 12px;
        color: var(--lightgray-font-color);
        text-transform: uppercase;
        padding: 5px 16px 3px 16px;
        margin-bottom: 5px;
        letter-spacing: 0.2px;
        width: 175px;
        white-space: nowrap;
        overflow: initial;
        text-overflow: ellipsis;
    }
}
