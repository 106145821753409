/* .gs-registration-page {
  background-color: var(--pagination-bk-color);
} */

.gs-registration-page {
    /* background: var(--pagination-bk-color); */
    background: url("/public/image/LoginMainImg.png"),
        linear-gradient(41.42deg, #e6f6fd -29.72%, #fbfdff 30.42%, #ffffff 75.24%);
    background-size: 100% 100%;
    background-repeat: no-repeat;
    background-position: left bottom;
    overflow-y: auto;
    height: 100vh;
    background-size: cover;
}

/* .gs-registration-fields {
  width: 85%;

  background-color: var(--white-color);
  display: flex;
  flex-direction: column;
  padding: 20px 50px;
  gap: 15px;
  overflow-y: auto;
  justify-content: space-between;
  height: 100vh;
} */

.gs-registration-fields {
    /* height: fit-content; */
    width: 80%;
    background-color: var(--white-color);
    display: flex;
    flex-direction: column;
    padding: 30px 35px;
    gap: 10px;
    /* overflow-y: auto; */
    justify-content: space-between;
    box-shadow: 0px 4px 60px rgba(0, 0, 0, 0.07);
    border-radius: 20px;
    max-width: 80%;
    margin: 30px 0;
    padding: 30px 35px 30px 35px;
    /* margin-top: 30px; */
}
.gs-registration-banner {
    height: 100vh;
    display: flex;
    flex-direction: column;
}

.gs-registration-banner-rightcontainer {
    /* width: 50%; */
    background: transparent;
}
.gs-registration-banner-leftcontainer {
    /* width: 50%; */
    /* color: gray; */
    height: auto !important;
}

.gs-registration-social-wrapper p {
    margin-top: 5px;
}
.gs-registration-header {
    /* margin-top: 5px; */
    display: none;
}
.gs-registration-social {
    display: flex;
    gap: 20px;
}
.gs-registration-social-item {
    width: 50%;
    border: 1px solid var(--border-color);
    height: 50px;
    display: flex;
    gap: 15px;
    border-radius: 5px;
    align-items: center;
    justify-content: center;
    cursor: pointer;
}
.gs-registration-social-item:hover,
.gs-registration-social-item:focus {
    background-color: var(--light-gray-color);
    border: 1px solid var(--primary-color);
}
.gs-registration-social-item p {
    font-size: 14px;
    line-height: 18px;
    color: var(--secondary-color);
}
.gs-registration-form {
    display: flex;
    flex-direction: column;
    gap: 10px;
    height: 80%;
    padding-top: 10px;
}
.gs-registration-header-text {
    display: flex;
    flex-direction: column;
    gap: 5px;
}
.gs-registration-header-text h1 {
    font-size: 27px;
    color: var(--secondary-color);
    font-weight: 500;
}
.gs-registration-or {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 10px;
    margin: 14px 0;
}
/* .gs-login-banner-rightcontainer {
  width: 50%;
}
.gs-login-banner-leftcontainer {
  width: 50%;
} */
.gs-registration-or-line {
    width: 47%;
    height: 1px;
    /* border: 1px solid #f0f0f0; */

    background-color: var(--border-color);
}
.gs-registration-or p {
    text-align: center;
    width: 150px;
    color: var(--secondary-light-color);
}

.gs-registration-form-fields {
    display: flex;
    flex-direction: column;
    gap: 20px;
    margin: 4px 0 0 0;
}

.gs-registration-form-fields-forgot {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-top: -12px;
}
.gs-registration-form-fields-forgot-checkbox {
    display: flex;
    align-items: center;
    gap: 5px;
}
.gs-registration-form-fields-forgot-checkbox input {
    background: #ffffff;
    border: 1px solid #f0f0f0;
    border-radius: 5px;
    height: 18px;
    width: 18px;
}
.forget-text {
    font-size: 14px;
    line-height: 18px;
    color: var(--primary-color);
}

.gs-registration-form-signup-button {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 0px;
}
.signup-signin-text {
    font-size: 14px !important;
    line-height: 18px !important;
    color: var(--primary-color) !important;
    cursor: pointer !important;
    padding: 0 !important;
    text-transform: capitalize !important;
}
.signup-signin-text:hover {
    background-color: transparent !important;
}

.signup-terms-text {
    font-size: 13px !important;
    line-height: 18px !important;
    color: var(--primary-color) !important;
    cursor: pointer !important;
    padding: 0 !important;
    text-transform: capitalize !important;
}

.signup-terms-text:hover {
    background-color: transparent !important;
}
.gs-registration-footer {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 5px;
    font-size: 10px;
    flex-direction: column;
    margin-top: 10px;
    text-align: center;
    flex: none;
}
.gs-registration-footer p {
    font-size: 12px;
}
.gs-registration-footer button {
    font-size: 12px !important;
    text-transform: none !important;
}

:global(.gs-privacy-policy) {
    display: flex;
    flex-direction: row;
    gap: 3px;
}

:global(.gs-password-icon svg) {
    font-size: 20px;
    color: var(--secondary-light-color);
}

:global(.gs-password-icon) {
    right: 5px;
    position: relative !important;
}

:global(.gs-password-icon:hover) {
    background-color: transparent !important;
}

.gs-registration-topblock {
    flex: 1;
}

/*-----------------MEDIA CSS--------------------*/
@media screen and (max-width: 1600px) {
    /* .gs-registration-footer {
    margin-top: 100px;
  }
  .gs-registration-fields {
    overflow-y: auto;
    height: 100vh;
  } */
}
@media screen and (max-width: 1366px) {
    /* .gs-registration-form {
    height: 85%;
    gap: 0px;
  } */
    .gs-registration-form-fields {
        gap: 15px;
    }
    .gs-registration-social {
        margin-top: 15px;
    }
    /* .gs-registration-footer {
    margin-top: 75px;
  } */
    .gs-registration-banner {
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        width: 50%;
    }

    .gs-registration-fields {
        width: 100%;
        max-width: 90%;
    }
}

@media screen and (max-width: 1199px) {
    :global(.gs-privacy-policy) {
        flex-direction: column;
    }
}
@media screen and (max-width: 1150px) {
    :global(.gs-privacy-policy) {
        flex-direction: column;
    }
}

@media screen and (max-width: 1099px) {
    .gs-registration-fields {
        width: 100%;
    }
    .gs-registration-header-text h1 {
        font-size: 24px;
    }
}

@media screen and (max-width: 899px) {
    .gs-registration-banner {
        display: none;
    }

    .gs-registration-fields {
        margin: 0;
        max-width: 100%;
        height: 100%;
        border-radius: 0;
    }
}

@media screen and (max-width: 768px) {
    .gs-login-social {
        flex-direction: row;
    }
    .gs-registration-banner-rightcontainer {
        display: none;
    }
    .gs-registration-banner-leftcontainer {
        width: 100%;
    }
}

@media screen and (max-width: 500px) {
    .gs-login-social {
        flex-direction: column;
        gap: 15px;
    }
}
