.card {
    border: 1px solid var(--border-color);
    border-radius: 5px;
    width: 100%;
    overflow: hidden;
    display: flex;
    flex-direction: column;
}

.body {
    /* background-color: var(--white-color); */
    background-color: var(--background-color);
    flex-grow: 1;
}

.header {
    background-color: var(--background-color);
    padding: 20px 20px 0 20px;
    /* border-bottom: 1px solid var(--border-color); */
}
