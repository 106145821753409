.search-input {
    background-color: var(--white-color);
    border-radius: 8px;
    box-shadow: none;
}

.search-input fieldset {
    border-radius: 6px;
    border: none;
}

.search-input :global(.Mui-focused) fieldset {
    border: 1px solid var(--primary-color) !important;
    padding: 13px 0 13px 0;
    margin: -3px 0;
}

.search-input input {
    color: var(--secondary-light-color);
    padding: 5px 15px;
    font-family: var(--regular-font);
    font-size: 14px;
}

/* CLOSE ICON -- START */
.close-icon {
    padding: 0 !important;
}
.close-icon svg {
    color: #dddddd;
    transition: color 0.5s;
    font-size: 18px;
}

.close-icon:hover {
    background-color: transparent !important;
}

.close-icon:hover svg {
    color: var(--dark-gray);
}
/* CLOSE ICON -- END */
