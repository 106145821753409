.action {
    padding-top: 0px !important;
}

.alert {
    border-radius: 5px !important;

    overflow: hidden;
    position: relative;
    display: flex;
    align-items: center;
}
.alert.medium {
    padding: 8px 16px !important;
}
.alert.small {
    padding: 0px 16px !important;
}

.alert.toast {
    background-color: var(--white-color) !important;
    border: none !important;
    padding: 9px 20px 9px 16px !important;
}

.alert.toast::after {
    content: "";
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    width: 8px;
}

.icon {
    height: 34px;
    width: 34px;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 8px;
    color: var(--white-color) !important;
}

.alert.success {
    border: 1px solid var(--success-color);
    background-color: var(--success-light-2-color);
}
.alert.toast.success {
    box-shadow: 0px 0px 10px 1px rgba(63, 189, 97, 0.15);
}
.alert.toast.success::after {
    background-color: var(--success-color);
}
.alert.success .icon {
    background-color: var(--success-color);
    box-shadow: 0px 4px 4px 2px rgba(63, 189, 97, 0.2);
}
.alert.toast.success .icon {
    background-color: var(--success-light-3-color);
    box-shadow: none;
    color: var(--success-color) !important;
}
.alert.toast.success .action {
    color: var(--success-color);
}

.alert.error {
    border: 1px solid var(--danger-color);
    background-color: var(--danger-light-2-color);
}
.alert.toast.error {
    box-shadow: 0px 0px 10px 1px rgba(233, 78, 44, 0.15);
}
.alert.toast.error::after {
    background-color: var(--danger-color);
}
.alert.error .icon {
    background-color: var(--danger-color);
    box-shadow: 0px 4px 4px 2px rgba(233, 78, 44, 0.2);
}
.alert.toast.error .icon {
    background-color: var(--danger-light-3-color);
    box-shadow: none;
    color: var(--danger-color) !important;
}
.alert.toast.error .action {
    color: var(--danger-color);
}

.alert.warning {
    border: 1px solid var(--warning-color);
    background-color: var(--warning-light-2-color);
}
.alert.toast.warning {
    box-shadow: 0px 0px 10px 1px rgba(238, 149, 0, 0.15);
}
.alert.toast.warning::after {
    background-color: var(--warning-color);
}
.alert.warning .icon {
    background-color: var(--warning-color);
    box-shadow: 0px 4px 4px 2px rgba(238, 149, 0, 0.2);
}
.alert.toast.warning .icon {
    background-color: var(--warning-light-3-color);
    box-shadow: none;
    color: var(--warning-color) !important;
}
.alert.toast.warning .action {
    color: var(--warning-color);
}

.alert.info {
    border: 1px solid var(--info-color);
    background-color: var(--info-light-2-color);
}
.alert.toast.info {
    box-shadow: 0px 0px 10px 1px rgba(3, 103, 219, 0.15);
}
.alert.toast.info::after {
    background-color: var(--info-color);
}
.alert.info .icon {
    background-color: var(--info-color);
    box-shadow: 0px 4px 4px 2px rgba(3, 103, 219, 0.2);
}
.alert.toast.info .icon {
    background-color: var(--info-light-3-color);
    box-shadow: none;
    color: var(--info-color) !important;
}
.alert.toast.info .action {
    color: var(--info-color);
}

.message {
    display: flex;
    align-items: center;
    font-size: 15px;
    font-family: var(--medium-font);
}

@media (max-width: 1600px) {
    .alert.small {
        padding: 4px 16px !important;
    }
}
