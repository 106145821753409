.table-header-wrap {
    padding: 0px !important;
}

.table-payment-header {
    display: flex;
    flex-direction: column;
}

.gs-payment-response-table :global(.MuiDataGrid-columnSeparator--resizable svg) {
    margin-top: -64px !important;
}

.gs-payment-response-table :global(.MuiDataGrid-columnHeaderTitleContainerContent) {
    width: 100%;
}
