.gs-footer {
    width: 100%;
    background: var(--white-color);
    height: 30px;
    border-top: 1px solid var(--border-color);
    display: flex;
    justify-content: space-between;
    padding: 7px 15px;
    color: var(--secondary-light-color);
    font-size: 12px;
    line-height: 15px;
    margin-top: 20px;
}
