/*?-----------------------BAGES COMPONENTS -----------------------*/

.gs-badges :global(.MuiBadge-badge) {
    background: var(--primary-color);
    color: var(--white-color);
}

.gs-badges-dot :global(.MuiBadge-badge) {
    background: var(--primary-color);
}

.gs-badges-text :global(.MuiBadge-badge) {
    background: var(--primary-color);
    font-size: 12px;
    font-family: var(--medium-font);
}

.gs-badges-secondary :global(.MuiBadge-badge) {
    background: var(--secondary-color);
    font-size: 12px;
    font-family: var(--medium-font);
    margin: 0px -15px;
}

.gs-badges-error :global(.MuiBadge-badge) {
    background: var(--danger-color);
    font-size: 12px;
    font-family: var(--medium-font);
}

.gs-badges-warning :global(.MuiBadge-badge) {
    background: var(--warning-color);
    font-size: 12px;
    font-family: var(--medium-font);
    margin: 0px -15px;
}

.gs-badges-info :global(.MuiBadge-badge) {
    background: var(--info-color);
    font-size: 12px;
    font-family: var(--medium-font);
}

.gs-badges-success :global(.MuiBadge-badge) {
    background: var(--success-color);
    font-size: 12px;
    font-family: var(--medium-font);
    margin: 0px -10px;
}
