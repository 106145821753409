.gs-tabs :global(.MuiButtonBase-root-MuiTab-root) {
    font-family: var(--medium-font);
    font-style: normal;
    font-weight: 400;
    font-size: 13px;
    line-height: 18px;
    color: var(--light-font-color);
    text-transform: uppercase;
}

.gs-tabs :global(.MuiButtonBase-root.MuiTab-root.Mui-selected) {
    color: var(--pagination-text-color);
    background-color: var(--sidebar-link-bk-color);
    border-top-left-radius: 5px;
    border-bottom-left-radius: 5px;
}
.gs-tabs button {
    text-transform: capitalize;
    letter-spacing: 0.2px;
    color: var(--secondary-light-color);
}

.gs-tabs-indicator {
    /* height: 4px !important; */
    background-color: transparent !important;
    display: flex;
    justify-content: center;
}

.indicator {
    border-top-left-radius: 30px;
    border-top-right-radius: 30px;
    display: inline-block;
    height: 59px;
    width: 47px;
    background-color: var(--pagination-text-color);
}

.gs-tabs :global(.MuiTab-root:first-child) {
    margin-left: 0px;
}

.gs-tabs :global(.MuiTabs-indicator:first-child) {
    margin-left: 20px;
}

.gs-tab-tabpanel :global(.MuiTypography-root) {
    font-family: var(--medium-font);
}

.gs-tab-Data :global(.MuiButtonBase-root.MuiTab-root.Mui-selected) {
    font-family: var(--medium-font);
    color: yellow;
}

.gs-button-tabs :global(.MuiButtonBase-root-MuiTab-root) {
    font-family: var(--regular-font);
    font-style: normal;
    font-weight: 400;
    font-size: 13px;
    line-height: 18px;
    color: var(--light-font-color);
    background-color: var(--light-bk-color);
    text-transform: uppercase;
    border: 1px solid var(--light-border-color);
}

.gs-button-tabs :global(.MuiButtonBase-root.MuiTab-root.Mui-selected) {
    color: var(--secondary-color);
    background-color: var(--white-color);
    font-family: var(--medium-font);
}

.gs-button-tabs :global(.MuiTab-root) {
    border-left: 1px solid var(--border-color);
    border-top: 1px solid var(--border-color);
}

.gs-button-tabs :global(.MuiTab-root:last-child) {
    border-right: 1px solid var(--border-color);
}
