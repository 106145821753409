.gs-breadcrumbs:global(.MuiBreadcrumbs-root) {
    font-weight: 400;
    font-size: 16px;
    line-height: 19px;
    letter-spacing: 0.00938em;
    font-style: normal;
    color: var(--gray-font-color);
    font-family: var(--medium-font);
    margin-top: 3px;
}

.gs-breadcrumbs :global(.MuiBreadcrumbs-li) a {
    font-family: var(--medium-font);
    text-decoration: none;
    color: var(--gray-font-color);
    font-size: 13px;
    text-transform: capitalize;
}

.gs-breadcrumbs :global(.MuiBreadcrumbs-li) p {
    text-transform: capitalize;
    font-family: var(--medium-font);
    font-weight: 400;
    font-size: 13px;
    line-height: 18px;
    letter-spacing: 0.00938em;
    font-style: normal;
    color: var(--gray-font-color);
}

@media screen and (max-width: 1050px) {
    .gs-breadcrumbs :global(.MuiBreadcrumbs-li) p {
        text-overflow: ellipsis;
        display: block;
        overflow: hidden;
        white-space: nowrap;
        width: 50px;
    }
}

@media screen and (max-width: 991px) {
    .gs-breadcrumbs {
        display: none;
    }
}
