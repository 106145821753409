.card.h-full {
    height: 100%;
}
.table-wrapper {
    border: none !important;
}
.table-wrapper.h-full {
    height: 100%;
}

.headers-wrapper {
    border-color: var(--border-color) !important;
    border-radius: 0 !important;
}

.table-header {
    padding: 16px 4px 16px 7px !important; /*16px 15px !important */
    color: var(--secondary-light-color) !important;
    border-color: var(--border-color) !important;
    background-color: var(--primary-light-color);
}

.table-header .header-title {
    /* text-transform: uppercase; */
    font-family: var(--semi-bold-font) !important;
    font-size: 14px;
    margin-left: 10px;
}

.table-header .header-title-container {
    overflow: visible !important;
    gap: 5px;
}
/* .table-header:not(:last-child) {
  border-right: 1px solid var(--border-color);
} */
.table-header,
.cell {
    outline: unset !important;
}

.table-header :global(.MuiBadge-root) {
    margin: 0 !important;
}

.cell {
    color: var(--secondary-color);
    padding: 14px 16px !important;
    border-color: var(--border-color) !important;
    border-bottom: none !important;
    background-color: var(--white-color) !important;
    font-size: 14px !important;
}

.cell[data-field="__detail_panel_toggle__"],
.table-header[data-field="__detail_panel_toggle__"] {
    padding: 0 10px !important;
}

/* .cell:not(:last-child) {
  border-right: 1px solid var(--border-color);
} */

/* .row:nth-child(2n) .cell,
.row:hover .cell {
  background-color: var(--background-color) !important;
} */

.row:not(:last-child) .cell {
    border-bottom: 1px solid var(--border-color) !important;
    /* font-size: 14px !important; */
}

.footer-wrapper {
    border-color: var(--border-color) !important;
    padding: 15px;
    display: flex !important;
    align-items: center !important;
    justify-content: right !important;
    background-color: var(--white-color);
}

.pagination :global(.MuiPaginationItem-root) {
    color: var(--dark-gray) !important;
}

.pagination :global(.MuiPaginationItem-root.Mui-selected),
.pagination :global(.MuiPaginationItem-root:not(.MuiPaginationItem-ellipsis)):hover {
    background-color: var(--primary-light-color) !important;
    color: var(--primary-color) !important;
}

.search-wrapper {
    height: 60px;
    display: flex;
    align-items: center;
    padding: 0 25px;
    background-color: var(--white-color);
    color: var(--secondary-light-color);
    border-bottom: 1px solid var(--border-color);
}

.search-wrapper svg {
    color: var(--secondary-light-color);
}

.search-wrapper input {
    flex-grow: 1;
    padding: 10px 15px;
    outline: none;
    border: 0;
    color: var(--secondary-light-color);
    background-color: var(--white-color);
}

.toolbar-wrapper {
    width: 100%;
    overflow: hidden;
}

.toolbar-wrapper::-webkit-scrollbar {
    height: 0px;
}

.toolbar {
    background-color: var(--white-color);
    border-bottom: 1px solid var(--border-color);
}

.toolbar-cell {
    height: 100%;
    padding: 15px 40px;
    float: left;
    min-height: 30px;
}

.scroll::-webkit-scrollbar {
    height: 5px !important;
}

.progress {
    height: 2px !important;
    background-color: var(--primary-light-color) !important;
}

.progress :global(.MuiLinearProgress-barColorPrimary) {
    background-color: var(--primary-color);
}

.detail-panel {
    padding: 16px 40px;
    border-bottom: 1px solid var(--border-color);
}

.expand-all-icon {
    height: 28px !important;
    width: 28px !important;
    padding: 5px !important;
}

.expand-all-icon svg {
    font-size: 18px;
}
