.gs-navbar {
    width: 100%;
    height: 60px;
    background: var(--white-color);
    border-bottom: 1px solid var(--border-color);
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    flex-wrap: nowrap;
    align-content: center;
    align-items: center;
}

.gs-navbar-left-wrapper {
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    align-content: flex-start;
    align-items: center;
    gap: 10px;
    /* width: 40%; */
}

.gs-navbar-rigth-wrapper {
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    align-content: center;
    justify-content: flex-end;
    align-items: center;
    gap: 10px;
    /* width: 60%; */
    padding-right: 20px;
}

.gs-navbar-search {
    width: 300px !important;
    border-radius: 6px;
    background: var(--white-color);
    height: 34px;
    border: 1px solid var(--border-color) !important;
    box-shadow: none !important;
    justify-content: center;
    align-items: center;
}

.gs-navbar-search input {
    font-size: 13px;
}

.gs-theme-button {
    /* background-color: var(--white-color) !important;
    border-radius: 5px !important;
    height: 34px;
    width: 34px;
    border: 1px solid var(--border-color) !important; */
    background-color: var(--white-color) !important;
    border-radius: 5px !important;
    border: 1px solid var(--border-color) !important;
    display: flex;
    align-items: center;
    gap: 7px;
    padding: 8px 10px;
    cursor: pointer;
}

.gs-theme-button span {
    color: var(--primary-color);
    font-family: var(--medium-font);
    line-height: 18px;
    font-size: 14px;
}

.gs-navbar-img-wrapper {
    padding: 0 20px;
    display: flex;
    flex-wrap: nowrap;
    align-content: center;
    justify-content: center;
    align-items: center;
    gap: 10px;
    cursor: pointer;
}

.gs-navbar-typo {
    text-transform: capitalize;
    color: var(--primary-color) !important;
}

.gs-navbar-breadcrumbs li.MuiBreadcrumbs-separator {
    margin-left: 5px;
    margin-right: 5px;
}

/* Profile Panel Css*/
.gs-profile-panel-button {
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    align-content: center;
    justify-content: center;
    gap: 15px;
    padding: 0 !important;
}

.gs-profile-text-wrapper,
.gs-profile-text-wrapper-poopover {
    display: flex;
    flex-direction: column;
    flex-wrap: nowrap;
    align-content: center;
    justify-content: center;
    text-transform: initial;
    font-family: var(--medium-font);
    font-size: 12px;
    align-items: flex-start;
}

.gs-profile-text-wrapper-poopover-gs-pover-text {
    display: flex;
    flex-direction: column;
    flex-wrap: nowrap;
    align-content: center;
    justify-content: center;
    text-transform: initial;
    align-items: flex-start;
    gap: 2px;
}

.gs-switch-role-dropdown :global(.MuiSvgIcon-root) {
    right: 15px;
}

.gs-profile-text-wrapper-poopover-gs-pover-text .gs-simple-dropdown .select {
    padding: 10px !important;
}

.gs-profile-text-wrapper-poopover-gs-pover-text .gs-profile-name-popover {
    color: var(--secondary-light-color);
    font-size: 13px;
}

.gs-profile-text-wrapper-poopover-gs-pover-text .gs-profile-company-name-popover {
    color: #a5c4d3;
    font-size: 12px;
    font-family: var(--regular-font) !important;
}

.gs-profile-company-name {
    color: var(--subline-text-color);
    font-size: 12px;
    position: relative;
    top: -4px;
}

.gs-profile-name,
.gs-profil-down-arrow-icons {
    color: var(--secondary-light-color);
    font-size: 12px;
}

.gs-profile-panel-button .gs-avatar {
    border-radius: 5px;
}

.gs-profile-panel-button:hover {
    background-color: transparent !important;
}

.gs-popover.MuiPopover-paper {
    height: 393px !important;
    /* width: 200px !important; */
}

.gs-popover :global(.MuiPopover-paper) {
    box-shadow: 0px 5px 5px -3px rgb(0 0 0 / 0%), 0px 4px 20px 1px var(--box-shadow-color),
        0px 3px 5px 2px rgb(0 0 0 / 0%);
    background-color: var(--white-color);
    opacity: 1;
    transform: none;
    transition: opacity 304ms cubic-bezier(0.4, 0, 0.2, 1),
        transform 202ms cubic-bezier(0.4, 0, 0.2, 1);
    top: 54px !important ;
    left: 1637px;
    transform-origin: 10.4219px 0px;
}

.gs-avtar-wrapper {
    padding: 20px 20px 15px 20px;
    display: flex;
    flex-direction: row;
    gap: 15px;
    border-bottom: 1px solid var(--border-color);
    background-color: var(--sidebar-link-bk-color);
}

.gs-switch-role-dropdown {
    /* min-width: 375px !important; */
    width: 104px !important;
    height: 23px !important;
    margin-top: 10px !important;
}

.gs-action-button {
    width: 267px !important;
    display: flex !important;
    flex-direction: row !important;
    align-content: flex-start !important;
    justify-content: flex-start !important;
    align-items: center;
    border: none !important;
    background: none !important;
    box-shadow: none !important;
}

.gs-action-button :global(.MuiButton-startIcon) {
    margin-right: 10px;
    margin-left: 0px;
}

.gs-action-button-logout-button :global(.MuiButton-startIcon) {
    margin-right: 10px;
    margin-left: 0px;
}

.gs-action-button:hover {
    border: none !important;
    background: none !important;
    box-shadow: none !important;
    color: var(--primary-color) !important;
}

.gs-action-button:hover span svg path {
    fill: var(--primary-color);
}

.gs-button-wrapper {
    border-bottom: 1px solid var(--border-color);
}

.gs-action-button-logout-button {
    border: none !important;
    color: var(--danger-color) !important;
    width: 267px !important;
    display: flex !important;
    flex-direction: row !important;
    align-content: flex-start !important;
    justify-content: flex-start !important;
    align-items: center;
    border: none !important;
    background: none !important;
    box-shadow: none !important;
}

.gs-account-wrapper {
    display: flex;
    border-bottom: 1px solid var(--border-color);
    padding: 10px 20px;
    flex-direction: row;
    flex-wrap: nowrap;
    align-items: center;
    gap: 15px;
    cursor: pointer;
}

.gs-compnay-text-wrapper {
    display: flex;
    flex-direction: column;
}

.gs-compnay-text-wrapper span {
    font-size: 13px;
    color: var(--secondary-light-color);
}

:global(.gs-avatar) {
    background-color: #f5f5f5 !important;
}

@media screen and (max-width: 850px) {
    .gs-profile-text-wrapper {
        display: none;
    }
}

@media screen and (max-width: 641px) {
    .gs-navbar-search {
        width: 180px !important;
    }
}

@media screen and (max-width: 510px) {
    .gs-navbar-logo-text {
        display: none;
    }

    .gs-navbar-search {
        width: 120px !important;
    }
}
