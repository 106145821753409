.gs-banner-wrapper {
    display: flex;
    align-items: flex-start;
    flex-direction: column;
    height: 90%;
    gap: 40px;
    /* padding: 0px 60px 60px 60px; */
    justify-content: center;
}

.gs-banner-log-image {
    cursor: pointer;
}

.gs-banner-content {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    gap: 12px;
    position: relative;
    z-index: 1;
}

.gs-banner-content .header-text {
    font-size: 34px;
    line-height: 50px;
    font-weight: 800;
    /* text-transform: uppercase;
    color: #6097d0; */
}

/* .gs-banner-content h2 {
    font-weight: 500;
    font-size: 25px;
    line-height: 32px;
    text-transform: uppercase;
    color: #6097d0;
  } */
.gs-banner-content h1 {
    color: var(--secondary-color);
    font-size: 30px;
    line-height: 40px;
    font-weight: 500;
}

.login-bannner-img {
    height: 70%;
}

.gs-access-main {
    height: calc(100vh - 130px);
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    /* width: calc(100vw - 300px); */
}

.gs-access-main h2 {
    color: var(--secondary-color);
    /* text-transform: uppercase; */
    font-size: 24px;
    font-weight: 400;
    margin-top: 25px;
}

.gs-description-text {
    margin-top: 15px;
    width: 710px;
    text-align: center;
    line-height: 22px;
    font-size: 15px;
}

.gs-banner-bookdemo-content h1 {
    text-align: left;
    font-size: 40px;
    line-height: 55px;
}

.gs-banner-questions {
    margin-top: 20px;
}

.gs-banner-questions h3 {
    font-size: 20px;
    color: #6097d0;
    font-weight: 400;
}

.gs-banner-questions p {
    padding: 0;
    color: #6097d0;
    font-size: 17px;
    padding-top: 10px;
}

.four-blocks-banner {
    display: grid;
    grid-template-columns: auto auto;
}

.gs-featureblock {
    display: inline-flex;
    flex-direction: row;
    padding-top: 40px;
}

.gs-featureblock img {
    width: 60px;
    height: 30px;
}

.gs-text-block-banner {
    padding: 0px 5px 0px 20px;
}

.gs-text-block-banner h4 {
    font-weight: 400;
    text-transform: uppercase;
}

.gs-text-block-banner p {
    padding: 10px 0 0 0;
    font-size: 15px;
    line-height: 20px;
}

.gs-featureblock-img {
    width: 60px;
    height: 60px;
    border-radius: 50%;
    /* gap: 10px; */
    background-color: rgba(96 151 208 / 19%);
    display: flex;
    justify-content: center;
    align-items: center;
}

.footer-bookdemo-login {
    display: flex;
}

.contain-box {
    display: flex;
    /* flex-direction: row; */
    /* justify-content: flex-start; */
    align-items: center;
    gap: 15px;
    /* height: 13%; */
    padding: 15px 10px 15px 20px;
    width: 82%;
    background: var(--white-color);
    border-radius: 10px;
}

.text-container {
    display: flex;
    flex-direction: column;
    width: 80%;
    gap: 5px;
}

.text-container p:first-child {
    color: var(--black-color);
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 18px;
}

.gs-banner-main-wrapper {
    height: 100%;
    padding: 70px 100px;
    position: relative;
}

.gs-banner-version {
    font-size: 13px;
    padding: 15px 100px;
}

/*-----------------MEDIA CSS--------------------*/

@media screen and (max-width: 1366px) {
    .login-bannner-img {
        height: auto;
    }

    .contain-box {
        width: 100%;
    }

    .gs-banner-wrapper {
        gap: 20px;
    }

    .gs-banner-bookdemo-content h1 {
        font-size: 35px;
    }
}

@media screen and (max-width: 1280px) {
    .gs-banner-main-wrapper {
        padding: 20px 30px;
    }

    .gs-banner-version {
        padding: 10px 30px;
    }

    .four-blocks-banner {
        grid-template-columns: initial;
    }
}

@media screen and (max-width: 1099px) {
    .login-bannner-img {
        height: 100%;
        width: 100%;
    }
}

/* .gs-banner-wrapper {
  display: flex;
  align-items: center;
  flex-direction: column;
  height: 100%;
  gap: 40px;
  position: relative;
  padding: 60px;
  justify-content: space-around;
}
.gs-banner-content {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  gap: 12px;
  position: relative;
  z-index: 1;
  text-align: center;
}
.gs-banner-content h2 {
  font-weight: 500;
  font-size: 25px;
  line-height: 32px;
  text-transform: uppercase;
  color: #6097d0;
}
.gs-banner-content h1 {
  color: var(--secondary-color);
  font-size: 30px;
  line-height: 40px;
  font-weight: 500;
}
.login-bannner-img {
  height: 70%;
} */

/* .gs-access-main {
  height: calc(100vh - 130px);
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
} */
/* width: calc(100vw - 300px); */

/* .gs-access-main h2 {
  color: var(--secondary-color);
  font-size: 24px;
  font-weight: 400;
  margin-top: 25px;
} */
/* text-transform: uppercase; */
/* .gs-description-text {
  margin-top: 15px;
  width: 710px;
  text-align: center;
  line-height: 22px;
  font-size: 15px;
}

.gs-banner-bookdemo-content h1{
text-align: left;
font-size: 40px;
line-height: 55px;
}

.gs-banner-questions{
  margin-top: 20px; 
}

.gs-banner-questions h3 {
  font-size: 20px;
  color: #6097d0;
  font-weight: 400;
}

.gs-banner-questions p {
  padding: 0;
  color: #6097d0;
  font-size: 17px;
  padding-top: 10px;
}
.four-blocks-banner {
  display: grid;
  grid-template-columns: auto auto; 
}
.gs-featureblock {
  display: inline-flex;
  flex-direction: row;
  padding-top: 40px;
}
.gs-featureblock img {
  width: 60px;
  height: 30px;
}
.gs-text-block-banner {
  padding: 0px 5px 0px 20px;
}
.gs-text-block-banner h4 {
  font-weight: 400;
  text-transform: uppercase;
}
.gs-text-block-banner p {
  padding: 10px 0 0 0;
  font-size: 15px;
  line-height: 20px;
} */
/* .gs-featureblock-img{
  width: 60px;
  height: 60px;
  border-radius: 50%;
  background-color: rgba(96 151 208 / 19%);
  display: flex;
  justify-content: center;
  align-items: center;
} */
/* gap: 10px; */

/* .footer-bookdemo-login{
  display: flex;
} */
/*-----------------MEDIA CSS--------------------*/

/* @media screen and (max-width: 1366px){
  .login-bannner-img {
    height: auto;
  }
}
@media screen and (max-width: 1099px) {
  .login-bannner-img {
    height: 100%;
    width: 100%;
  }
  .gs-banner-wrapper {
    padding: 40px;
  }
} */
