.label {
    color: var(--dark-black);
    margin-bottom: 10px;
    display: inline-block;
    font-size: 14px;
    font-family: var(--medium-font);
}

.gs-email-input {
    border: 1px solid var(--dark-gray);
    font-family: var(--regular-font);
    border: none;
    outline: none;
    color: var(--dark-gray);
    font-size: 15px;
    width: 100%;
    height: 40px;
}

.gs-email-field-container {
    color: var(--dark-gray);
    font-size: 15px;
    font-family: var(--regular-font);
    padding: 5px 10px;
    border: 1px solid var(--light-color);
    border-radius: 5px;
    height: auto;
}

.gs-email-field-container:focus-within {
    border-color: var(--primary-color);
}

.helper-text {
    margin-top: 8px;
    display: flex;
    align-items: center;
    gap: 10px;
}

.helper-text > * {
    color: var(--danger-color);
}
