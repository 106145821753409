.alert {
    width: 100% !important;
    max-width: 500px !important;
    background-color: var(--white-color) !important;
    border-radius: 5px !important;
    box-shadow: none !important;
    padding: 0 !important;
}

.content {
    padding: 0 !important;
    background-color: var(--white-color);
    overflow-y: hidden;
}

.header {
    padding: 15px 20px !important;
    display: flex;
    align-items: center;
    justify-content: space-between;
    border-bottom: 1px solid var(--border-color);
}

.close {
    border-radius: 3px !important;
    padding: 0;
    height: 30px;
    width: 30px;
    right: -8px;
}

.close:hover {
    text-decoration: none;
    background-color: rgba(25, 118, 210, 0.04) !important;
}

.close svg {
    font-size: 18px;
    fill: #666666;
}

.body {
    padding: 20px;
    display: flex;
    align-items: center;
}

.body .icon {
    height: 40px;
    width: 40px;
}

.footer {
    padding: 0 20px 20px 20px;
    display: flex;
    align-items: center;
    justify-content: flex-end;
    gap: 15px;
}

.btn {
    padding: 0 16px !important;
    border-radius: 5px;
    text-transform: none;
    height: 36px;
    text-transform: capitalize !important;
}

.btn.confirm-btn {
    background-color: var(--danger-color);
    border: 1px solid var(--danger-color);
    color: var(--white-color);
}

.btn.confirm-btn:hover {
    background-color: var(--danger-hover);
    border: 1px solid var(--danger-hover);
}

.btn.cancel-btn {
    color: var(--secondary-light-color);
    border: 1px solid var(--secondary-light-color);
    background-color: transparent;
    transition: all ease 0.1s;
}

.btn.cancel-btn:hover {
    background-color: var(--secondary-light-color);
    color: var(--white-color);
}

.gs-security-signout-modal :global(.MuiBackdrop-root) {
    background-color: var(--backdrop-color);
}
