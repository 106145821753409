.wrapper {
    background-color: var(--white-color);
    border: 1px solid var(--border-color);
    cursor: pointer;
    border-radius: 5px;
}

.range-text {
    font-size: 14px;
    font-weight: 500;
    color: var(--secondary-light-color);
    padding: 0 12px;
    display: inline-block;
    font-family: var(--medium-font) !important;
    min-height: 36px;
    line-height: 36px;
}

.date-text {
    font-size: 14px;
    font-weight: 500;
    color: var(--secondary-light-color);
    border-left: 1px solid var(--border-color);
    padding: 6px 12px;
    display: inline-block;
    font-family: var(--medium-font) !important;
}

.popover {
    box-shadow: 0px 7px 64px rgba(0, 0, 0, 0.07) !important;
    margin-top: 5px;
    display: flex;
    background-color: var(--white-color);
    box-shadow: 0 5px 40px var(--box-shadow-color) !important;
}

.popover .menu {
    border-right: 1px solid var(--border-color);
    min-width: 160px;
    overflow-y: auto;
}

.popover .menu .menu-item {
    font-size: 13px;
    padding: 11px 20px;
    cursor: pointer;
    color: var(--secondary-light-color);
}

.popover .menu .menu-item:hover,
.popover .menu .menu-item.active {
    color: var(--primary-color);
    transition: all 0.5s;
}

.popover .menu .menu-item:not(:last-child) {
    border-bottom: 1px solid var(--border-color);
}

.picker {
    border-radius: 5px !important;
    overflow: hidden !important;
    background-color: var(--white-color);
}

.picker :global(.MuiDateRangePickerViewDesktop-root) {
    background-color: var(--white-color);
}
.picker
    :global(.MuiDateRangePickerViewDesktop-root .MuiDayPicker-header .MuiDayPicker-weekDayLabel) {
    color: var(--secondary-light-color);
}
.picker
    :global(
        .MuiDateRangePickerViewDesktop-root
            .MuiDayPicker-monthContainer
            .MuiDateRangePickerDay-root
            .MuiDateRangePickerDay-rangeIntervalPreview
            .MuiPickersDay-root.Mui-disabled
    ) {
    color: var(--disable-font-color);
}
.picker
    :global(
        .MuiDateRangePickerViewDesktop-root
            .MuiDayPicker-monthContainer
            .MuiDateRangePickerDay-root
            .MuiDateRangePickerDay-rangeIntervalPreview
            .MuiPickersDay-root
    ) {
    color: var(--secondary-color);
}

.picker
    :global(
        .MuiDateRangePickerViewDesktop-root
            > .MuiDateRangePickerViewDesktop-container:not(:last-child)
    ) {
    border-right: 1px solid var(--border-color) !important;
}

.picker :global(.MuiPickersArrowSwitcher-button) {
    box-shadow: 0px 7px 20px rgba(0, 0, 0, 0.07) !important;
    background-color: var(--white-color) !important;
    border-radius: 6px !important;
}

.picker :global(.MuiPickersDay-today:not(.Mui-selected)) {
    border-color: var(--primary-light-color) !important;
}

.picker :global(.MuiDateRangePickerDay-day.Mui-selected) {
    background-color: var(--primary-color) !important;
    color: var(--white-color) !important;
}

.picker
    :global(
        .MuiDateRangePickerDay-rangeIntervalPreview.MuiDateRangePickerDay-rangeIntervalDayPreview
    ) {
    border: 2px dashed var(--primary-light-color);
    border-left-color: transparent;
    border-right-color: transparent;
}

.picker
    :global(
        .MuiDateRangePickerDay-root:last-of-type .MuiDateRangePickerDay-rangeIntervalDayPreview
    ) {
    border-right-color: var(--primary-light-color);
}

.picker :global(.MuiDateRangePickerDay-rangeIntervalPreview:not(.Mui-selected) button:hover) {
    border-color: var(--primary-color) !important;
    background-color: var(--primary-light-color);
}

.picker :global(.MuiDateRangePickerDay-root.MuiDateRangePickerDay-rangeIntervalDayHighlight) {
    background-color: var(--primary-light-color) !important;
}

.picker :global(.PrivatePickersSlideTransition-root) {
    min-height: 220px;
}

@media screen and (max-width: 641px) {
    .date-text {
        display: none;
    }
}
