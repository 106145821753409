.gs-content-wrapper-container-header {
    padding: 20px 30px;
    overflow: none;
}
.gs-content-wrapper-container-header-title {
    display: flex;
    align-items: center;
    justify-content: space-between;
}
.gs-content-wrapper-container-title {
    font-size: 20px;
    line-height: 26px;
    color: var(--secondary-color);
}
.gs-content-wrapper-container-description {
    color: var(--secondary-light-color);
    font-size: 14px;
    line-height: 22px;
}
.gs-content-wrapper-container-dummydata {
    color: var(--danger-color);
    font-size: 15px;
    line-height: 25px;
    display: flex;
    flex-direction: row;
    gap: 5px;
    align-items: center;
    justify-content: flex-start;
}
.gs-content-wrapper-container-children {
    padding: 0 30px 20px 30px;
    height: calc(100vh - 150px);
    overflow-y: auto;
}

.gs-content-wrapper-container-children :global(.css-kcgekf) {
    border-bottom: none;
}

/*----EXPORT----*/
.gs-export-popover :global(.MuiPopover-paper) {
    width: 168px;
    box-shadow: 0px 5px 40px rgba(0, 0, 0, 0.1);
}

.gs-export-popover:global(.MuiPopover-root) {
    pointer-events: none;
}

.gs-export-popover :global(.MuiBackdrop-root) {
    pointer-events: none;
}

.gs-button-action-wrapper {
    display: flex;
    flex-direction: column;
    box-shadow: 0px 5px 40px rgba(0, 0, 0, 0.1);
    background-color: var(--white-color);
    border-radius: 5px;
    border: 1px solid var(--border-color);
    /* justify-content: center; */
    /* position: absolute; */
}

.gs-button-wrapper:first-child button:hover {
    border-top-left-radius: 5px;
    border-top-right-radius: 5px;
    border-bottom-left-radius: 0px;
    border-bottom-right-radius: 0px;
}

.gs-button-wrapper {
    border-bottom: 1px solid var(--border-color);
    pointer-events: all;
}

.gs-button-wrapper .gs-action-button :global(.MuiButton-startIcon) {
    margin-right: 12px;
}

.gs-button-wrapper .gs-export-button :global(.MuiButton-startIcon) {
    margin-right: 12px;
}

.gs-export-button {
    width: 180px !important;
    display: flex !important;
    padding: 10px 20px !important;
    flex-direction: row !important;
    align-content: flex-start !important;
    justify-content: flex-start !important;
    align-items: center;
    border: none !important;
    background: none !important;
    box-shadow: none !important;
}

.gs-export-button:hover {
    border: none !important;
    background: none !important;
    box-shadow: none !important;
    color: var(--primary-color) !important;
    background: var(--light-gray-color) !important;
    border-radius: 0px;
    transition: all ease 0.2s;
}

.gs-export-button:hover span svg path {
    fill: var(--primary-color);
}

.gs-button-wrapper .gs-export-button :global(.MuiButton-startIcon) {
    margin-right: 12px;
}

.gs-delete-button-wrapper .gs-export-button :global(.MuiButton-startIcon) {
    margin-right: 12px;
}

.gs-navbar-search {
    width: 300px !important;
    border-radius: 6px;
    background: var(--white-color);
    height: 34px;
    border: 1px solid var(--border-color) !important;
    box-shadow: none !important;
    justify-content: center;
    align-items: center;
}

.gs-navbar-search input {
    font-size: 13px;
}

@media screen and (max-width: 850px) {
    .gs-navbar-search {
        width: 80px !important;
    }
}

@media screen and (max-width: 450px) {
    .gs-content-wrapper-container-header-title {
        flex-direction: column;
        gap: 15px;
    }
    .gs-navbar-search {
        width: 60px !important;
    }
}
