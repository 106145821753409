.helper-text p {
    font-size: 14px;
    font-style: normal;
    letter-spacing: 0.2px;
    font-weight: 400;
}

.helper-text > * {
    color: var(--danger-color);
}
