@import "./Font.css";
@import "./Variables.css";

* {
    margin: 0;
    font-family: var(--medium-font) !important;
    box-sizing: border-box;
    padding: 0;
}

*:focus-within {
    outline: none !important;
}

h1,
h2,
h3,
h4,
h5 {
    font-family: var(--medium-font);
}

html {
    font-size: 14px;
}

body {
    background: var(--white-color);
    font-size: 14px;
    /* background: white; */
}
.apexcharts-canvas svg {
    overflow: visible;
}

p {
    font-family: var(--medium-font);
    color: var(--secondary-light-color);
    font-size: 14px;
}

.p-20 {
    padding: 20px !important;
}

.p-0 {
    padding: 0 !important;
}

/*?--------------------  Color  -----------------------*/

.gs-primary-color {
    color: var(--primary-color);
}

.gs-secondary-color {
    color: var(--secondary-color);
}

.gs-success-color {
    color: var(--success-color);
}

.gs-danger-color {
    color: var(--danger-color);
}

.gs-warning-color {
    color: var(--warning-color);
}

.gs-info-color {
    color: var(--info-color);
}

.primary-icon path {
    fill: var(--primary-color);
}
.close-icon {
    height: 10px;
    width: 10px;
}
.warning-icon path {
    fill: var(--warning-color);
}
.close-icon path {
    fill: var(--secondary-light-color);
    stroke: var(--secondary-light-color);
    stroke-width: 1px;
}
.danger-icon path {
    fill: var(--danger-color);
}

.secondary-light-icon path {
    fill: var(--secondary-light-color);
}

.secondary-light-icon-stroke path {
    stroke: var(--secondary-light-color);
}

.secondary-light-icon line {
    stroke: var(--secondary-light-color);
}

.secondary-primary-icon path {
    fill: var(--primary-color);
}

.secondary-primary-icon-stroke path {
    stroke: var(--primary-color);
}

.secondary-primary-icon line {
    stroke: var(--primary-color);
}

.grey-icon path {
    fill: #767676;
}

.light-grey-icon circle {
    fill: var(--lightgray-font-color);
}
.light-grey-icon path {
    fill: var(--lightgray-font-color);
}

.secondary-icon path {
    fill: var(--secondary-color);
}

.success-icon path {
    fill: var(--success-color);
}

.white-icon path {
    fill: var(--white-color);
}

/*?--------------------  SCROLL BAR  -----------------------*/
/**------> WIDTH <------**/
/* body ::-webkit-scrollbar {
  width: 4px;
  border-radius: 5px;
  height: 30px;
  font-family: "BRHendrix_Regular";
} */

body ::-webkit-scrollbar:horizontal {
    width: 4px;
    border-radius: 5px;
    height: 5px;
}

body ::-webkit-scrollbar {
    width: 4px;
    border-radius: 5px;
    height: 30px;
}

::-webkit-scrollbar {
    width: 4px;
    border-radius: 5px;
    height: 30px;
}

/**------> TRACK <------**/
body ::-webkit-scrollbar-track {
    background: var(--white-color);
}

::-webkit-scrollbar-track {
    background: var(--white-color);
}

/**------> THUMB <------**/
body ::-webkit-scrollbar-thumb {
    background: var(--lightgray-font-color);
    border-radius: 5px;
    width: 1px;
    height: 20px;
}

::-webkit-scrollbar-thumb {
    background: var(--lightgray-font-color);
    border-radius: 5px;
    width: 2px;
    height: 20px;
}

/**------> HOVER <------**/
/* body ::-webkit-scrollbar-thumb:hover {
  background-color: var(--light-gray-color);

  display: block;
}

::-webkit-scrollbar-thumb:hover {
  background-color: var(--light-gray-color);

  display: block;
} */

/**------> TABLE CSS <------**/
.gs-simple-table {
    font-family: var(--medium-font);
    border-color: var(--white-color);
    /* height: 100%; */
    /* padding: 10px 15px 0px 15px; */
    background-color: var(--white-color);
}

.gs-simple-table .MuiDataGrid-row:nth-of-type(even) {
    background-color: var(--header-bk-color);
    border-bottom: 1px solid var(--border-color);
}

.gs-simple-table .MuiDataGrid-columnHeader .MuiButtonBase-root {
    margin-left: 5px;
}

.gs-simple-table .MuiDataGrid-row {
    background-color: var(--white-color);
}

.gs-simple-table.css-1e2bxag-MuiDataGrid-root .MuiDataGrid-columnSeparator--sideRight {
    display: none;
}

.gs-simple-table .css-12wnr2w-MuiButtonBase-root-MuiCheckbox-root {
    color: var(--dark-gray);
}

.gs-simple-table .css-17jjc08-MuiDataGrid-footerContainer {
    justify-content: center;
    padding: 20px;
    border-bottom-left-radius: 7px;
    border-bottom-right-radius: 7px;
}

.gs-simple-table .MuiDataGrid-columnHeaders {
    background-color: #f8f8f8;
    border-top-left-radius: 7px;
    border-top-right-radius: 7px;
}

.gs-simple-table .MuiDataGrid-virtualScroller {
    background-color: #fdfdfd;
}

.gs-simple-table .MuiDataGrid-footerContainer {
    background-color: #fdfdfd;
}

.gs-simple-table .MuiDataGrid-footerContainer .MuiDataGrid-selectedRowCount {
    font-family: var(--medium-font);
}

.gs-simple-table .MuiDataGrid-columnHeaderTitle {
    font-size: 14px;
    color: var(--secondary-light-color);
    font-family: var(--medium-font);
}

.gs-simple-table .MuiDataGrid-columnHeader,
.gs-simple-table .MuiDataGrid-cell {
    border-right: 1px solid var(--border-color);
    padding: 0 20px !important;
}

.gs-simple-table .MuiDataGrid-row:hover {
    background-color: rgba(25, 118, 210, 0.08) !important;
}

.gs-simple-table .MuiDataGrid-cell .MuiDataGrid-cellContent {
    color: var(--secondary-color);
    font-size: 14px;
    font-family: var(--medium-font);
}

/**------> PAGINATIONS CSS <------**/
.gs-table-pagination.css-1oj2twp-MuiPagination-root
    .css-1to7aaw-MuiButtonBase-root-MuiPaginationItem-root.Mui-selected {
    color: var(--pagination-text-color);
    background-color: var(--pagination-bk-color);
    height: 40px;
    width: 40px;
    border-radius: 100%;
    font-family: var(--medium-font);
    font-size: 15px;
}
.gs-table-pagination .MuiPaginationItem-page {
    background-color: var(--white-color);
    height: 40px;
    width: 40px;
    border-radius: 100%;
    font-family: var(--medium-font);
    font-size: 15px;
    color: var(--secondary-light-color);
}

.gs-table-pagination .Mui-disabled {
    background-color: var(--white-color);
    height: 40px;
    width: 40px;
    border-radius: 100%;
    font-family: var(--medium-font);
    color: #bdbdbd;
    font-size: 15px;
}

.gs-table-pagination .MuiPaginationItem-previousNext {
    background-color: var(--white-color);
    height: 40px;
    width: 40px;
    border-radius: 100%;
    font-family: var(--medium-font);
    color: var(--secondary-light-color);
    font-size: 15px;
}

/*?----------------------- BUTTON COMPONENT -----------------------*/
.gs-primary-button.MuiButton-root,
.gs-danger-button.MuiButton-root,
.gs-secondary-button.MuiButton-root,
.gs-tertiary-button.MuiButton-root .gs-warning-button.MuiButton-root,
.gs-dark-button.MuiButton-root,
.gs-light-button.MuiButton-root,
.gs-success-button.MuiButton-root,
.gs-secondary-other-button.MuiButton-root {
    display: flex;
    gap: 5px;
    border: none;
    height: 32px;
    border-radius: 5px;
    padding: 8px 20px;
    transition: all 0.3s;
    color: var(--white-color);
    text-transform: none;
    font-family: var(--medium-font) !important;
    letter-spacing: 0px;
    font-size: 14px;
    /* min-width: 100px; */
    line-height: 21px;
    /* box-shadow: 0px 4px 10px 0px rgb(0 0 0 / 8%); */
    width: auto;
    transition: all ease 0.3s;
}
.gs-primary-button span,
.gs-danger-button span,
.gs-secondary-button span,
.gs-warning-button span,
.gs-dark-button span,
.gs-light-button span,
.gs-success-button span,
.gs-secondary-other-button span {
    margin: 0px !important;
}

.gs-tertiary-button span {
    margin: 3px !important;
}

.gs-primary-button span svg,
.gs-danger-button span svg,
.gs-secondary-button span svg,
.gs-tertiary-button span svg,
.gs-warning-button span svg,
.gs-dark-button span svg,
.gs-light-button span svg,
.gs-success-button span svg,
.gs-secondary-other-button span svg {
    font-size: 20px !important;
}

.gs-primary-ghost-button.MuiButton-root,
.gs-danger-ghost-button.MuiButton-root,
.gs-secondary-ghost-button.MuiButton-root,
.gs-tertiary-ghost-button.MuiButton-root,
.gs-disable-ghost-button.MuiButton-root,
.gs-dark-ghost-button.MuiButton-root,
.gs-light-ghost-button.MuiButton-root,
.gs-success-ghost-button.MuiButton-root,
.gs-secondary-other-ghost-button.MuiButton-root {
    height: 36px;
    border-radius: 5px;
    /* padding: 11px 27px; */
    padding: 10px 20px;
    letter-spacing: 0.2px;
    border: solid 0.1px;
    transition: all 0.3s;
    font-family: var(--medium-font);
    background-color: var(--white-color);
    text-transform: none;
    /* box-shadow: 0px 4px 10px 0px rgb(0 0 0 / 6%); */
    font-size: 14px;
    line-height: 26px;
    /* min-width: 100px; */
    transition: all ease 0.3s;
}

.gs-primary-rounded-button.MuiButton-root,
.gs-danger-rounded-button.MuiButton-root,
.gs-secondary-rounded-button.MuiButton-root,
.gs-tertiary-rounded-button.MuiButton-root,
.gs-disable-rounded-button.MuiButton-root,
.gs-dark-rounded-button.MuiButton-root,
.gs-light-rounded-button.MuiButton-root,
.gs-success-rounded-button.MuiButton-root,
.gs-secondary-other-rounded-button.MuiButton-root {
    border: none;
    height: 36px;
    border-radius: 50px;
    padding: 11px 27px;
    transition: all 0.3s;
    font-family: var(--medium-font);
    color: var(--white-color);
    font-size: 14px;
    text-transform: none;
    min-width: 100px;
    box-shadow: 0px 2px 6px 0px rgb(0 0 0 / 6%);
    transition: all ease 0.3s;
}

/**------> PRIMARY BUTTON <------**/
.gs-primary-button.MuiButton-root,
.gs-primary-rounded-button.MuiButton-root {
    background-color: var(--primary-color);
}

.gs-primary-button.MuiButton-root:focus,
.gs-primary-rounded-button.MuiButton-root:focus {
    background-color: var(--primary-color);
    outline: 1px solid var(--primary-color);
    outline-offset: 2px;
}

.gs-primary-button.MuiButton-root:hover,
.gs-primary-rounded-button.MuiButton-root:hover {
    background-color: var(--primary-bk-color);
    transition: all 0.3s;
}
.gs-primary-button.MuiButton-root:disabled {
    background-color: var(--primary-bk-color);
    color: var(--white-color);
    transition: all 0.3s;
    opacity: 0.6;
}

/**------> DANGER BUTTON <------**/
.gs-danger-button.MuiButton-root,
.gs-danger-rounded-button.MuiButton-root {
    background-color: var(--danger-color);
}

.gs-danger-button.MuiButton-root:focus,
.gs-danger-rounded-button.MuiButton-root:focus {
    outline: 1px solid var(--danger-color);
    outline-offset: 2px;
}

.gs-danger-button.MuiButton-root:hover,
.gs-danger-rounded-button.MuiButton-root:hover {
    background-color: var(--danger-bk-color);
    transition: all 0.3s;
}
.gs-danger-button.MuiButton-root:disabled {
    background-color: var(--danger-bk-color);
    color: var(--white-color);
    transition: all 0.3s;
    opacity: 0.6;
}

/**------> SECONDARY BUTTON <------**/
.gs-secondary-button.MuiButton-root,
.gs-secondary-rounded-button.MuiButton-root {
    background-color: var(--secondary-color);
}

.gs-secondary-button.MuiButton-root:focus,
.gs-secondary-rounded-button.MuiButton-root:focus {
    background-color: var(--secondary-color);
    outline: 1px solid var(--secondary-color);
    outline-offset: 2px;
}

.gs-secondary-button.MuiButton-root:hover,
.gs-secondary-rounded-button.MuiButton-root:hover {
    background-color: var(--secondary-bk-color);
    transition: all 0.3s;
}

/**------> SECONDARY Other BUTTON <------**/
.gs-secondary-other-button.MuiButton-root,
.gs-secondary-other-rounded-button.MuiButton-root {
    background-color: var(--dark-gray);
}

.gs-secondary-other-button.MuiButton-root:focus,
.gs-secondary-other-rounded-button.MuiButton-root:focus {
    background-color: var(--dark-gray);
    outline: 1px solid var(--dark-gray);
    outline-offset: 2px;
}

.gs-secondary-other-button.MuiButton-root:hover,
.gs-secondary-other-rounded-button.MuiButton-root:hover {
    background-color: var(--secondary-light-color);
    transition: all 0.3s;
}

/**------> Tertiary BUTTON <------**/
.gs-tertiary-button.MuiButton-root {
    background-color: transparent;
    color: var(--primary-color);
    box-shadow: none;
    text-transform: inherit;
    font-family: var(--medium-font);
    font-size: 14px;
    min-width: 100px;
    padding: 11px 27px;
    letter-spacing: 0.2px;
}

.gs-tertiary-button.MuiButton-root:focus,
.gs-tertiary-rounded-button.MuiButton-root:focus {
    outline: 1px solid var(--primary-color);
    outline-offset: 2px;
}

.gs-tertiary-rounded-button.MuiButton-root {
    background-color: var(--tertiary-bk-color);
    color: var(--primary-color);
}

.gs-tertiary-button.MuiButton-root:hover {
    background-color: var(--tertiary-bk-color);
    transition: all 0.3s;
    box-shadow: 0px 2px 6px 0px rgb(0 0 0 / 6%);
}

.gs-tertiary-rounded-button.MuiButton-root:hover {
    background-color: var(--tertiary-bk-color);
    transition: all 0.3s;
}

/*--------------------------- GHOST BUTTONS--------------------------------------//

/**------> PRIMARY BUTTON <------**/
.gs-primary-ghost-button.MuiButton-root,
.gs-primary-ghost-rounded-button.MuiButton-root {
    color: var(--primary-color);
    border-color: var(--primary-color);
}

.gs-primary-ghost-button.MuiButton-root:hover,
.gs-primary-ghost-rounded-button.MuiButton-root:hover {
    background: var(--sidebar-link-bk-color);
    color: var(--primary-bk-color);
    border-color: var(--primary-bk-color);
}

/**------> DANGER BUTTON <------**/
.gs-danger-ghost-button.MuiButton-root,
.gs-danger-ghost-rounded-button.MuiButton-root {
    color: var(--danger-color);
    border-color: var(--danger-color);
}

.gs-danger-ghost-button.MuiButton-root:hover,
.gs-danger-ghost-rounded-button.MuiButton-root:hover {
    background: var(--danger-color);
    color: var(--white-color);
    border-color: var(--danger-color);
}

/**------> SECONDARY  BUTTON <------**/
.gs-secondary-ghost-button.MuiButton-root,
.gs-secondary-ghost-rounded-button.MuiButton-root {
    color: var(--secondary-light-color);
    border-color: var(--secondary-light-color);
}

.gs-secondary-ghost-button.MuiButton-root:hover,
.gs-secondary-ghost-rounded-button.MuiButton-root:hover {
    background: var(--sidebar-link-bk-color);
    color: var(--primary-bk-color);
    border-color: var(--primary-bk-color);
}
/**------> SECONDARY OTHERS BUTTON <------**/
.gs-secondary-other-ghost-button.MuiButton-root,
.gs-secondary-other-ghost-rounded-button.MuiButton-root {
    color: var(--secondary-light-color);
    border-color: #555555;
    /* var(--primary-color) */
}

.gs-secondary-other-ghost-button.MuiButton-root:hover,
.gs-secondary-other-ghost-rounded-button.MuiButton-root:hover {
    background: var(--sidebar-link-bk-color);
    color: var(--primary-bk-color);
    border-color: var(--primary-color);
    /* border-color: var(--dark-gray); */
}

/**------> Tertiary BUTTON <------**/
.gs-tertiary-ghost-button.MuiButton-root {
    color: var(--tertiary-color);
    border-color: var(--tertiary-color);
}

.gs-tertiary-ghost-rounded-button.MuiButton-root {
    color: var(--tertiary-color);
    border-color: var(--tertiary-color);
    background: var(--tertiary-color);
}

.gs-tertiary-ghost-button.MuiButton-root:hover,
.gs-tertiary-ghost-rounded-button.MuiButton-root:hover {
    background: var(--tertiary-bk-color);
    color: var(--tertiary-color);
    border-color: var(--tertiary-bk-color);
}

/**------> Disabled BUTTON <------**/
.gs-disable-button.MuiButton-root {
    background: var(--disabled-color);
    color: var(--gray-font-color) !important;
    border-color: var(--disabled-color);
    text-transform: none;
    font-family: var(--medium-font);
    letter-spacing: 0px;
    font-size: 14px;
    height: 40px;
    cursor: no-drop !important;
    box-shadow: 0px 2px 6px 0px rgb(0 0 0 / 6%);
}

.gs-disable-button.MuiButton-root:hover {
    background: var(--disabled-color);
    color: var(--gray-font-color);
    border-color: var(--disabled-color);
}

.gs-disable-ghost-button.MuiButton-root {
    color: var(--gray-font-color);
    border-color: var(--disabled-color);
    cursor: no-drop;
}

.gs-disable-rounded-button.MuiButton-root {
    color: var(--gray-font-color) !important;
    border-color: var(--disabled-color);
    background: var(--disabled-color);
    cursor: no-drop !important;
}

.gs-disable-ghost-button.MuiButton-root:hover,
.gs-disable-rounded-button.MuiButton-root:hover {
    background: var(--white-color);
    color: var(--gray-font-color);
    border-color: var(--disabled-color);
}

.gs-disable-rounded-button.MuiButton-root:hover {
    background: var(--white-color);
    color: var(--gray-font-color);
    border-color: var(--disabled-color);
}

/**------> SUCCESS BUTTON <------**/
.gs-success-button.MuiButton-root,
.gs-success-button.MuiButton-root,
.gs-success-rounded-button.MuiButton-root {
    color: var(--white-color);
    border-color: var(--success-color);
    background-color: var(--success-color);
}

.gs-success-button.MuiButton-root:hover,
.gs-success-button.MuiButton-root:hover,
.gs-success-rounded-button.MuiButton-root:hover {
    background: var(--success-bk-color);
    color: var(--white-color);
    border-color: var(--success-bk-color);
}

.gs-success-ghost-button.MuiButton-root {
    color: var(--success-color);
    border: 1px solid var(--success-color);
}

.gs-success-ghost-button.MuiButton-root:hover {
    color: var(--white-color) !important;
    border: 1px solid var(--success-color) !important;
    background-color: var(--success-color) !important;
}

.gs-ImageUpload-button.MuiButton-root {
    display: flex;
    gap: 5px;
    border: 1px solid var(--border-color);
    height: 32px;
    border-radius: 0.35rem;
    padding: 0.78rem 1.92rem;
    transition: all 0.3s;
    color: var(--secondary-light-color);
    text-transform: none;
    font-family: var(--medium-font) !important;
    letter-spacing: 0px;
    font-size: 14px;
    min-width: 7.14rem;
    line-height: 20px;
    width: 100%;
    transition: all ease 0.3s;
    background-color: var(--white-color);
}

.gs-imageUpload-preview {
    max-width: 40px;
    aspect-ratio: 1;
    border: 1px solid var(--border-color);
    border-radius: 50%;
    cursor: pointer;
    padding: 5px;
}

/*---------------------Extra Large Buttons-----------------------*/
.extraLarge-button {
    height: 50px !important;
    font-size: 14px !important;
}

/*---------------------Large Buttons-----------------------*/
.large-button {
    height: 44px !important;
}

/*---------------------Medium Buttons-----------------------*/
.medium-button {
    height: 36px !important;
    font-size: 14px !important;
}

/*---------------------Small Buttons-----------------------*/
.small-button {
    height: 33px !important;
}
/*---------------------Small Buttons-----------------------*/
.e-small-button {
    height: 30px !important;
}

/** ----------> LABEL <-----------------------**/

.gs-simple-label {
    color: var(--secondary-color);
    margin-bottom: 10px;
    display: inline-block;
    font-size: 14px;
    font-family: var(--medium-font);
}

.gs-multiDropdown-list .gs-multiDropdown-option {
    padding: 10px 12px 0 12px;
}

.gs-multiDropdown-list .gs-multiDropdown-option:last-child {
    padding-bottom: 10px;
}

.gs-multiDropdown-list .gs-multiDropdown-option span {
    font-size: 15px;
    color: var(--secondary-light-color);
}

.gs-multiDropdown-list .gs-multiDropdown-option.Mui-focused {
    background: none;
    color: var(--primary-color);
}

.gs-multiDropdown-list .gs-multiDropdown-option.Mui-focused span {
    color: var(--primary-color);
}

.gs-multiSelect-dropdown .css-1gw1e0b {
    margin: 1px 0px 3px 5px;
    padding: 10px;
    height: 33px;
    gap: 2px;
}

.gs-multiSelect-dropdown input {
    padding: 10px !important;
}

.gs-multiSelect-dropdown .css-1gw1e0b:first-child {
    margin-left: 7px;
}
.gs-multiSelect-dropdown .css-1gw1e0b:last-child {
    margin-right: 15px;
}

.gs-multiSelect-dropdown .css-1gw1e0b span {
    font-size: 12px;
    font-family: var(--medium-font);
}

.gs-multiSelect-dropdown .css-1gw1e0b svg {
    font-size: 24px;
    color: var(--secondary-light-color);
    font-family: var(--medium-font);
}

/*---------------------Small Buttons-----------------------*/
/* .gs-modal .css-zw3mfo-MuiModal-root-MuiDialog-root {
  width: 50%;
}

.gs-modal .css-zw3mfo-MuiModal-root-MuiDialog-root hr {
  background-color: #e4e4e4;
}

.gs-modal .gs-modal-action-button {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  gap: 20px;
  padding-top: 25px;
}

.gs-modal .gs-modal-content {
  font-family: var(--medium-font);
  padding: 20px !important;
  font-size: 14px;
}

.gs-modal .MuiDialogTitle-root {
  font-family: var(--medium-font);
  font-weight: 400;
  display: flex;
  height: 60px;
  justify-content: space-between;
  align-items: center;
  border-bottom: 1px solid var(--border-color);
  font-size: 15px;
  color: var(--secondary-color);
}

.gs-modal .gs-primary-button,
.gs-danger-button {
  width: 130px;
  border-radius: 6px;
}

.gs-modal .gs-modal-close-botton {
  min-width: 24px !important;
  height: 24px !important;
  background-color: var(--light-gray);
  padding: 0;
}

.gs-modal .gs-modal-close-botton svg {
  color: #666666;
  width: 16px;
  height: 16px;
}

.gs-secondary-button.button-drawer {
  margin-top: 15px;
}

.gs-modal .MuiPaper-root {
  border-radius: 5px;
  box-shadow: none;
}

.gs-modal .MuiDialogContentText-root {
  text-align: center;
  font-family: var(--medium-font);
  font-size: 14px;
  line-height: 22px;
}

.gs-modal .gs-modal-content .MuiTypography-h6 {
  color: var(--secondary-color);
  font-family: var(--semi-bold-font);
} */

/*?----------------------- TABS COMPONENTS -----------------------*/
.gs-tabs .css-1h9z7r5-MuiButtonBase-root-MuiTab-root {
    font-family: var(--medium-font);
    font-style: normal;
    font-weight: 400;
    font-size: 13px;
    line-height: 18px;
    color: var(--light-font-color);
    text-transform: none;
}

.gs-tabs .css-1h9z7r5-MuiButtonBase-root-MuiTab-root.Mui-selected {
    color: var(--pagination-text-color);
    font-family: var(--medium-font);
}

.gs-tabs .css-1aquho2-MuiTabs-indicator,
.gs-button-tabs .css-1aquho2-MuiTabs-indicator {
    background-color: var(--pagination-text-color);
    height: 3px;
}

.gs-tabs .gs-tabs-indicator {
    border-top-left-radius: 30px;
    border-top-right-radius: 30px;
    height: 4px;
}

.gs-tabs .MuiTab-root:first-child {
    margin-left: 20px;
}

.gs-tabs .MuiTabs-indicator:first-child {
    margin-left: 20px;
}

.gs-tab-tabpanel .MuiTypography-root {
    font-family: var(--medium-font);
    font-size: 14px;
}

/*?----------------------- TABS BUTTON COMPONENTS -----------------------*/
.gs-button-tabs .css-1h9z7r5-MuiButtonBase-root-MuiTab-root {
    font-family: var(--medium-font);
    font-style: normal;
    font-weight: 400;
    font-size: 13px;
    line-height: 18px;
    color: var(--light-font-color);
    background-color: var(--light-bk-color);
    /* border: 1px solid var(--light-border-color); */
}

.gs-button-tabs .css-1h9z7r5-MuiButtonBase-root-MuiTab-root.Mui-selected {
    color: var(--secondary-color);
    background-color: var(--white-color);
    font-family: var(--medium-font);
}

.gs-button-tabs .MuiTab-root {
    border-left: 1px solid var(--border-color);
    border-top: 1px solid var(--border-color);
}

.gs-button-tabs .MuiTab-root:last-child {
    border-right: 1px solid var(--border-color);
}

/*?----------------------- TOOLTIP COMPONENTS -----------------------*/
.gs-tooltip .MuiTooltip-tooltip {
    background-color: var(--secondary-color);
    border-radius: 5px !important;
    display: flex;
    align-items: center;
    text-align: center;
    font-size: 0.9rem;
    font-style: normal;
    line-height: 1.28rem;
    padding: 0.7rem;
    font-family: var(--medium-font);
}
.gs-tooltip .MuiTooltip-arrow {
    color: var(--secondary-color);
}

/*?-----------------------STATUS COMPONENTS -----------------------*/
.gs-status-tip {
    padding: 0 20px;
    height: 26px;
    display: inline-flex;
    justify-content: center;
    align-items: center;
    border-radius: 5px;
    font-size: 15px;
    font-family: var(--medium-font);
    font-weight: 400;
    font-style: normal;
    width: 85px;
}

/*?-----------------------STEPPER COMPONENTS COMPONENTS -----------------------*/
.gs-stepper .css-1u4zpwo-MuiSvgIcon-root-MuiStepIcon-root.Mui-active,
.gs-stepper .css-1u4zpwo-MuiSvgIcon-root-MuiStepIcon-root.Mui-completed {
    color: var(--primary-color);
}
.gs-stepper .Mui-completed .css-z7uhs0-MuiStepConnector-line {
    border-color: var(--primary-color);
}

.custom-status-tip {
    gap: 5px;
    display: flex;
    align-items: center;
}

/* ---------------------- SPINNER LOADER ----------------------------------- */

@-webkit-keyframes spin {
    0% {
        -webkit-transform: rotate(0deg);
    }
    100% {
        -webkit-transform: rotate(360deg);
    }
}

@keyframes spin {
    0% {
        transform: rotate(0deg);
    }
    100% {
        transform: rotate(360deg);
    }
}

.spinner-loader {
    border: 4px solid #f3f3f3;
    border-radius: 50%;
    border-top: 4px solid var(--dark-gray);
    width: 28px;
    height: 28px;
    animation: spin 1s linear infinite;
}

/*-----------------nps animation ------------*/
@-webkit-keyframes fadeInDown {
    0% {
        opacity: 0;
        -webkit-transform: translate3d(0, -100%, 0);
        transform: translate3d(0, -100%, 0);
    }
    100% {
        opacity: 1;
        -webkit-transform: none;
        transform: none;
    }
}
@keyframes fadeInDown {
    0% {
        opacity: 0;
        -webkit-transform: translate3d(0, -100%, 0);
        transform: translate3d(0, -100%, 0);
    }
    100% {
        opacity: 1;
        -webkit-transform: none;
        transform: none;
    }
}

@-webkit-keyframes fadeInDown-lefttop {
    0% {
        opacity: 0;
        -webkit-transform: translate3d(0, -100%, 0);
        transform: translate3d(0, -100%, 0);
    }
    100% {
        opacity: 1;
        -webkit-transform: none;
        transform: none;
    }
}
@keyframes fadeInDown-lefttop {
    0% {
        opacity: 0;
        -webkit-transform: translate3d(0, -100%, 0);
        transform: translate3d(0, -100%, 0);
    }
    100% {
        opacity: 1;
        -webkit-transform: none;
        transform: none;
    }
}

@-webkit-keyframes fadeInUp {
    0% {
        opacity: 0;
        -webkit-transform: translate3d(0, 100%, 0);
        transform: translate3d(0, 100%, 0);
    }
    100% {
        opacity: 1;
        -webkit-transform: none;
        transform: none;
    }
}
@keyframes fadeInUp {
    0% {
        opacity: 0;
        -webkit-transform: translate3d(0, 100%, 0);
        transform: translate3d(0, 100%, 0);
    }
    100% {
        opacity: 1;
        -webkit-transform: none;
        transform: none;
    }
}
