/**------> TABLE CSS <------**/
.gs-simple-table {
    font-family: var(--medium-font);
    border-color: var(--white-color);
    background-color: var(--white-color);
}

.gs-simple-table :global(.MuiDataGrid-row:nth-of-type(even)) {
    background-color: var(--header-bk-color);
    border-bottom: 1px solid var(--border-color);
}

.gs-simple-table :global(.MuiDataGrid-columnHeader .MuiButtonBase-root) {
    margin-left: 5px;
}

.gs-simple-table :global(.MuiDataGrid-row) {
    background-color: var(--white-color);
}

.gs-simple-table :global(.MuiDataGrid-columnSeparator--sideRight) {
    display: none;
}

.gs-simple-table :global(.MuiButtonBase-root.MuiCheckbox-root) {
    color: var(--dark-gray);
}

.gs-simple-table :global(.MuiDataGrid-footerContainer) {
    justify-content: center;
    padding: 20px;
    border-bottom-left-radius: 7px;
    border-bottom-right-radius: 7px;
}

.gs-simple-table :global(.MuiDataGrid-columnHeaders) {
    background-color: #f8f8f8;
    border-top-left-radius: 7px;
    border-top-right-radius: 7px;
}

.gs-simple-table :global(.MuiDataGrid-virtualScroller) {
    background-color: #fdfdfd;
}

.gs-simple-table :global(.MuiDataGrid-footerContainer) {
    background-color: #fdfdfd;
}

.gs-simple-table :global(.MuiDataGrid-footerContainer .MuiDataGrid-selectedRowCount) {
    font-family: var(--medium-font);
}

.gs-simple-table :global(.MuiDataGrid-columnHeaderTitle) {
    font-size: 14px;
    color: var(--secondary-light-color);
    text-transform: uppercase;
    font-family: var(--medium-font);
}

.gs-simple-table :global(.MuiDataGrid-columnHeader),
.gs-simple-table :global(.MuiDataGrid-cell) {
    border-right: 1px solid var(--border-color);
}

.gs-simple-table :global(.MuiDataGrid-iconSeparator) {
    display: none;
}

.gs-simple-table :global(.MuiDataGrid-row):hover {
    background-color: rgba(25, 118, 210, 0.08) !important;
}

.gs-simple-table :global(.MuiDataGrid-cell .MuiDataGrid-cellContent) {
    color: var(--secondary-color);
    font-size: 14px;
    font-family: var(--medium-font);
}

/**------> PAGINATIONS CSS <------**/
.gs-table-pagination.MuiPaginationItem-root.Mui-selected {
    color: var(--pagination-text-color);
    background-color: var(--pagination-bk-color);
    height: 40px;
    width: 40px;
    border-radius: 100%;
    font-family: var(--medium-font);
    font-size: 15px;
}
.gs-table-pagination .MuiPaginationItem-page {
    background-color: var(--white-color);
    height: 40px;
    width: 40px;
    border-radius: 100%;
    font-family: var(--medium-font);
    font-size: 15px;
    color: var(--secondary-light-color);
}

.gs-table-pagination .Mui-disabled {
    background-color: var(--white-color);
    height: 40px;
    width: 40px;
    border-radius: 100%;
    font-family: var(--medium-font);
    color: #bdbdbd;
    font-size: 15px;
}

.gs-table-pagination .MuiPaginationItem-previousNext {
    background-color: var(--white-color);
    height: 40px;
    width: 40px;
    border-radius: 100%;
    font-family: var(--medium-font);
    color: var(--secondary-light-color);
    font-size: 15px;
}

.gs-simple-table :global(.MuiCircularProgress-root) {
    color: var(--primary-color);
}

.gs-progressbar:global(.MuiLinearProgress-root) {
    height: 3px;
    background: rgba(0, 171, 255, 0.14);
}
.gs-progressbar :global(.MuiLinearProgress-bar) {
    background-color: var(--primary-color);
}

.gs-avatar:global(.MuiAvatar-root) {
    border-radius: 5px;
}

.gs-button-action-wrapper {
    display: flex;
    flex-direction: column;
    /* justify-content: center; */
}
.gs-action-button {
    width: 170px !important;
    display: flex !important;
    flex-direction: row !important;
    align-content: flex-start !important;
    justify-content: flex-start !important;
    align-items: center;
    border: none !important;
    background: none !important;
    box-shadow: none !important;
}
.gs-delete-action-button {
    width: 170px !important;
    display: flex !important;
    flex-direction: row !important;
    align-content: flex-start !important;
    justify-content: flex-start !important;
    align-items: center;
    border: none !important;
    background: none !important;
    box-shadow: none !important;
}

.gs-action-button:hover {
    border: none !important;
    background: none !important;
    box-shadow: none !important;
    color: var(--primary-color) !important;
    background: var(--light-gray-color) !important;
    border-radius: 0px;
    transition: all ease 0.2s;
}

.gs-action-button:hover span svg path {
    fill: var(--primary-color);
}
.gs-actions-popover :global(.MuiPopover-paper) {
    width: 170px;
    box-shadow: 0px 5px 40px rgba(0, 0, 0, 0.1);
}
.gs-button-wrapper {
    border-bottom: 1px solid var(--border-color);
    pointer-events: all;
}

.gs-button-wrapper .gs-action-button :global(.MuiButton-startIcon) {
    margin-right: 12px;
}
.gs-delete-button-wrapper {
    border-bottom: 1px solid var(--border-color);
    pointer-events: all;
    background: #ff00000f;
}

.gs-delete-button-wrapper .gs-action-button :global(.MuiButton-startIcon) {
    margin-right: 12px;
}
.gs-delete-button-wrapper svg path {
    fill: red;
}

.gs-actions-popover:global(.MuiPopover-root) {
    pointer-events: none;
}

.gs-actions-popover :global(.MuiBackdrop-root) {
    pointer-events: none;
}

/* table Data css */

.gs-table-primary-text {
    color: var(--secondary-color);
    font-size: 14px !important;
    font-weight: 400 !important;
    cursor: default !important;
}

.gs-table-primary-text[data-highlight="true"] {
    color: var(--primary-color) !important;
}

.gs-table-primary-text[data-click-allow="true"]:hover {
    color: var(--primary-color) !important;
}

.gs-table-primary-text[data-click-allow="true"] {
    cursor: pointer !important;
}

.gs-table-secondary-text {
    color: #646f79;
    font-size: 12px !important;
    font-family: var(--regular-font) !important;
}

.gs-table-caption-text {
    color: #b4b4b4 !important;
    font-size: 12px !important;
    gap: 5px;
    font-family: var(--regular-font) !important;
}

.gs-avatar-img {
    width: 30;
    height: 30;
    background-color: var(--primary-color);
    font-size: 15px;
}
.gs-image {
    border-radius: 50%;
    height: 30px;
    width: 100%;
}
