.wrapper {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
}

/* LABEL -- START */
.label {
    color: var(--dark-gray);
    margin-bottom: 10px;
    display: inline-block;
    font-size: 14px;
    font-family: var(--medium-font);
}
/* LABEL -- END */

/* INPUT FIELD -- START */
.text-field input {
    color: var(--dark-gray);
    height: 44px;
    padding: 0 15px;
    /* border-radius: 5px; */
    font-size: 15px;
    font-family: var(--regular-font);
}

.text-field :global(.MuiInputBase-inputMultiline) {
    font-size: 14px;
    font-family: var(--regular-font);
    padding-right: 6px !important;
}

.text-field :global(.MuiInputBase-root) {
    padding-right: 6px !important;
    background-color: var(--white-color);
}

.text-field fieldset {
    border-width: 1px !important;
    border-radius: 4px !important;
}

.text-field :global(.Mui-focused) > fieldset,
.text-field:hover fieldset {
    border-color: var(--primary-color) !important;
}

.text-field :global(.MuiSvgIcon-root) {
    fill: var(--secondary-light-color);
    font-size: 18px;
}

.wrapper.error .text-field fieldset,
.wrapper.error .text-field :global(.Mui-focused) > fieldset,
.wrapper.error .text-field:hover fieldset {
    border-color: var(--danger-color) !important;
}

.wrapper.success .text-field fieldset,
.wrapper.success .text-field :global(.Mui-focused) > fieldset,
.wrapper.success .text-field:hover fieldset {
    border-color: var(--success-color) !important;
}
/* INPUT FIELD -- END */

/* HELPER MESSAGE -- START */
.wrapper .helper-text {
    margin-top: 8px;
    display: flex;
    align-items: center;
}
.wrapper .helper-text :not(:last-child) {
    margin-right: 4px;
}
.wrapper.error .helper-text > * {
    color: var(--danger-color);
}
.wrapper.error .helper-text > svg {
    width: 16px !important;
}
.wrapper.success .helper-text > * {
    color: var(--success-color);
}
/* HELPER MESSAGE -- END */

.success-icon {
    color: var(--success-color);
}

.text-field fieldset {
    border: 1px solid var(--border-color);
}
