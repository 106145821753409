.theme-title {
    font-size: 14px;
    font-weight: 400;
    line-height: 18px;
    letter-spacing: 0em;
}
.color-picker-close {
    position: fixed;
    top: 0px;
    right: 0px;
    bottom: 0px;
    left: 0px;
}
.theme-properties {
    display: flex;
    gap: 40px;
    margin-top: 20px;
}
.color-picker-button {
    border: 1px solid var(--border-color);
    border-radius: 50%;
    cursor: pointer;
}
.popover-container {
    margin-top: 5px;
}
.color-filed-name {
    font-size: 12px;
    line-height: 18px;
    letter-spacing: 0em;
    text-align: center;
    color: var(--secondary-light-color);
    margin-top: 10px;
}
.color-div {
    text-align: center;
}
