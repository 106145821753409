.gs-change-password-otp {
    height: 44px;
    width: 44px;
    border: 1px solid var(--border-color);
    border-radius: 5px;
    text-align: center;
    font-weight: 400;
    font-size: 17px;
    font-family: var(--medium-font);
    color: var(--primary-color);
    background-color: var(--white-color);
}

.gs-change-password-otp:focus-visible {
    outline: none;
    border: 1px solid var(--primary-color);
}

.gs-change-password-otp:hover {
    border: 1px solid var(--primary-color);
}

.gs-otp-input-wrapper {
    display: flex;
    gap: 10px;
}

@media (max-width: 1099px) {
    .gs-otp-input-wrapper {
        flex-wrap: wrap;
    }
}
