/* LABEL -- START */
.label {
    color: var(--dark-gray);
    margin-bottom: 8px;
    display: inline-block;
    font-weight: 500;
    font-size: 16px;
}

.gs-radio-group .MuiFormControlLabel-label {
    font-size: 14px;
    font-family: var(--regular-font);
    color: var(--secondary-light-color);
}

.gs-radio-group .MuiRadio-colorPrimary svg {
    height: 20px;
    width: 20px;
}
/* LABEL -- END */

.radio-group :global(.MuiFormControlLabel-label) {
    color: var(--dark-gray);
    font-size: 14px;
    font-family: var(--medium-font);
}

.radio-group :global(.Mui-checked + .MuiFormControlLabel-label) {
    color: var(--black-color);
}
