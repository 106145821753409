.checkbox {
    color: var(--dark-gray);
    font-size: 14px;
    font-family: var(--medium-font);
    margin-right: 0px !important;
    margin-left: 0px !important;
}

.checkbox :global(.MuiFormControlLabel-label) {
    font-size: 14px;
}
